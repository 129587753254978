import React, { useState, useRef, useEffect } from "react";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import {
  Row,
  Col,
  Select,
  Icon,
  DatePicker,
  Popover,
  Tree,
  Button,
} from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import VideoAndBookmark from "./VideoAndBookamark";
import Reports from "./Reports";
import { tabs, searchTree, DASHBOARD_TYPE, icons } from "./Constant";
import Tab from "./Tab";
import { connect } from "react-redux";
import * as moment from "moment";
import { loadEvent, loadVehicleType } from "../fetchAPI/apiClient";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TreeNode } = Tree;
let intervalVideoAndBookmark = null;

const Index = (props) => {
  const [currTab, setCurrTab] = useState(1);
  const [filterObj, setFilterObj] = useState({
    type: [],
    duration: 1,
    startDate: moment().format("YYYY-MM-DD 00:00:00"),
    endDate: moment().format("YYYY-MM-DD 23:59:59"),
    vehicle_group: 0,
  });
  const [loading, setLoading] = useState(false);
  const [vehicleGroup, setVehicleGroup] = useState([]);

  const filterObjRef = useRef(filterObj);

  useEffect(() => {
    filterObjRef.current = filterObj;
  }, [filterObj]);

  useEffect(() => {
    getEvent();
    getVehicleType();
    intervalVideoAndBookmark = setInterval(getVideoAndBookmark, 1000 * 30);
    return () => clearInterval(intervalVideoAndBookmark);
  }, []);

  const getVideoAndBookmark = () => {
    handleSearch(filterObjRef.current);
  };

  const getVehicleType = () => {
    loadVehicleType(
      {
        company_id: props.auth.profile.company_id,
      },
      (status, res) => {
        setVehicleGroup(res.data.data);
      }
    );
  };

  const videoAndBookmarkRef = useRef(); // Create a ref for VideoAndBookmark

  const ReportRef = useRef();

  useEffect(() => {
    if (filterObj.type.length > 0 && (currTab === 1 || currTab === 2)) {
      intervalVideoAndBookmark = setInterval(getVideoAndBookmark, 1000 * 30);
      handleSearch(filterObj);
    }

    if (filterObj.type.length > 0 && currTab === 3) {
      clearInterval(intervalVideoAndBookmark);
      handleReport(filterObj);
    }
  }, [currTab]);

  const handleReport = (filterObj) => {
    if (ReportRef.current) {
      setLoading(true);
      ReportRef.current.getReport(
        {
          date_start: filterObj.startDate,
          date_end: filterObj.endDate,
          event: filterObj.type,
          time_range: filterObj.duration,
          page: 1,
          user_id: props.auth.profile.id,
          vehicle_visibility: props.auth.profile.vehicle_visibility,
          company_id: props.auth.profile.company_id,
        },
        () => {
          setLoading(false);
        }
      );
    }
  };

  const getEvent = () => {
    loadEvent({}, (statusCode, response) => {
      let newType = extractKeys(response.data.data).filter(
        (item) => !["0", "1", "5"].includes(item)
      );

      setFilterObj((prev) => {
        handleSearch({
          ...prev,
          type: newType,
        });
        return {
          ...prev,
          type: newType,
        };
      });
    });
  };

  const extractKeys = (data) => {
    // Create a new array to store the keys
    const keys = [];

    // Iterate over the treeNode array
    data.treeNode.forEach((node) => {
      // If subTreeNode exists, iterate through and extract keys
      if (node.subTreeNode) {
        node.subTreeNode.forEach((subNode) => {
          keys.push(subNode.key);
        });
      }
    });

    // Return the keys array
    return keys;
  };

  const selectedTabs = (values) => {
    switch (values) {
      case 1:
        return (
          <VideoAndBookmark
            type={DASHBOARD_TYPE.video.type}
            ref={videoAndBookmarkRef}
            auth={props.auth}
            filterObj={filterObj}
          />
        );
      case 2:
        return (
          <VideoAndBookmark
            type={DASHBOARD_TYPE.bookmark.type}
            ref={videoAndBookmarkRef}
            auth={props.auth}
            filterObj={filterObj}
          />
        );
      case 3:
        return (
          <Reports filterObj={filterObj} auth={props.auth} ref={ReportRef} />
        );

      default:
        break;
    }
  };

  const handleSearch = (filterObj, page) => {
    if (videoAndBookmarkRef.current) {
      setLoading(true);
      videoAndBookmarkRef.current.getDashboard(
        {
          user_id: props.auth.profile.id,
          time_range: filterObj.duration,
          date_start: filterObj.startDate,
          date_end: filterObj.endDate,
          event: filterObj.type,
          vehicle_group: filterObj.vehicle_group,
          vehicle_visibility: props.auth.profile.vehicle_visibility,
          company_id: props.auth.profile.company_id,
          page: page != undefined ? 1 : videoAndBookmarkRef.current.page,
        },
        () => {
          setLoading(false);
          if (page != undefined) {
            videoAndBookmarkRef.current.setPage(1);
          }
        }
      );
    }
  };

  const findNamesByKeys = (tree, keys) => {
    const result = [];

    const searchNode = (node) => {
      if (keys.includes(node.key.toString())) {
        result.push(icons[node.name]["label"]);
      }

      if (node.treeNode) {
        node.treeNode.forEach(searchNode);
      }
      if (node.subTreeNode) {
        node.subTreeNode.forEach(searchNode);
      }
    };

    searchNode(tree);
    if (keys.includes("0") || (result.length === 0 && keys.length !== 0)) {
      return "เหตุการณ์ทั้งหมด";
    } else if (
      keys.includes("600,601") &&
      keys.includes("602,603,608,609") &&
      keys.includes("606,607") &&
      keys.includes("618,619") &&
      keys.includes("620,621") &&
      keys.includes("624,625") &&
      keys.includes("622,623") &&
      keys.includes("626,627") &&
      keys.includes("704,705")
    ) {
      return "เหตุการณ์ทั้งหมด";
    } else if (keys.length === 0) {
      return "ไม่ได้เลือกเหตุการณ์";
    } else {
      return `(${result.join(",")})`;
    }
  };

  return (
    <GeneralStyledContent>
      <Row gutter={[16, 16]} type="flex" justify="space-between" align="middle">
        <Col span={filterObj.duration == 3 ? 4 : 10} align="left">
          {tabs.map((item) => (
            <Tab
              active={currTab == item.id}
              name={item.name}
              onClick={() => {
                setCurrTab(item.id);
              }}
            />
          ))}
        </Col>
        <Col span={currTab === 1 ? 4 : 3}>
          <Select
            className="dashboard__adas_selected"
            defaultValue={0}
            style={{ width: "100%" }}
            size="large"
            placeholder="เลือกกลุ่มพาหนะ"
            onChange={(e) => {
              setFilterObj((prev) => {
                if (currTab === 3) {
                  handleReport({
                    ...prev,
                    vehicle_group: e,
                  });
                } else {
                  handleSearch({
                    ...prev,
                    vehicle_group: e,
                  });
                }
                return {
                  ...prev,
                  vehicle_group: e,
                };
              });
            }}
          >
            <Option value={0}>กลุ่มพาหนะทั้งหมด</Option>
            {vehicleGroup.map((item) => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Col>

        <Col span={4} align="right">
          <Popover
            placement="bottom"
            title={null}
            content={
              <div style={{ width: 300, borderRadius: 10, minHeight: 200 }}>
                <Tree
                  checkable
                  defaultExpandedKeys={[0, 1, 5]}
                  defaultCheckedKeys={[0]}
                  onCheck={(checkedKeys, info) => {
                    setFilterObj((prev) => {
                      let newType = checkedKeys.filter(
                        (item) => !["0", "1", "5"].includes(item)
                      );
                      if (currTab === 3) {
                        handleReport({
                          ...prev,
                          type: newType,
                        });
                      } else {
                        handleSearch({
                          ...prev,
                          type: newType,
                        });
                      }
                      return {
                        ...prev,
                        type: newType,
                      };
                    });
                  }}
                >
                  <TreeNode
                    title={searchTree["name"]}
                    key={searchTree["key"]}
                    selectable={false}
                  >
                    {searchTree["treeNode"].map((val) => (
                      <TreeNode
                        title={val["name"]}
                        key={val["key"]}
                        selectable={false}
                      >
                        {val["subTreeNode"].map((subVal) => (
                          <TreeNode
                            title={icons[subVal["name"]]["label"]}
                            key={subVal["key"]}
                            selectable={false}
                          />
                        ))}
                      </TreeNode>
                    ))}
                  </TreeNode>
                </Tree>
              </div>
            }
            trigger="hover"
          >
            <Select
              className="dashboard__adas_selected"
              defaultValue="0"
              style={{ width: "100%" }}
              size="large"
              open={false}
            >
              <Option value="0">
                {findNamesByKeys(searchTree, filterObj.type)}
              </Option>
            </Select>
          </Popover>
        </Col>
        {filterObj.duration == 3 ? (
          <Col span={6} align="left">
            <RangePicker
              showTime
              style={{ width: "100%" }}
              size="large"
              className="dashboard__adas_range_datepicker"
              onChange={(value) => {
                setFilterObj((prev) => {
                  if (currTab === 3) {
                    handleReport({
                      ...prev,
                      startDate: moment(value[0]).format("YYYY-MM-DD hh:mm:ss"),
                      endDate: moment(value[1]).format("YYYY-MM-DD hh:mm:ss"),
                    });
                  } else {
                    handleSearch({
                      ...prev,
                      startDate: moment(value[0]).format("YYYY-MM-DD hh:mm:ss"),
                      endDate: moment(value[1]).format("YYYY-MM-DD hh:mm:ss"),
                    });
                  }
                  return {
                    ...prev,
                    startDate: moment(value[0]).format("YYYY-MM-DD hh:mm:ss"),
                    endDate: moment(value[1]).format("YYYY-MM-DD hh:mm:ss"),
                  };
                });
              }}
            />
          </Col>
        ) : null}
        <Col align="right" span={4}>
          <Select
            className="dashboard__adas_selected"
            defaultValue={1}
            style={{ width: "100%" }}
            size="large"
            onChange={(e) => {
              setFilterObj((prev) => {
                if (currTab === 3) {
                  handleReport({
                    ...prev,
                    duration: e,
                  });
                } else {
                  handleSearch({
                    ...prev,
                    duration: e,
                  });
                }
                return {
                  ...prev,
                  duration: e,
                };
              });
            }}
            readOnly
          >
            {/* <Option value={0}>ขับต่อเนื่อง</Option> */}
            <Option value={1}>3 ชั่วโมง</Option>
            <Option value={2}>ทั้งวัน</Option>
            {/* <Option value={3}>กำหนดเอง</Option> */}
          </Select>
        </Col>
        <Col span={2} align="right">
          <Button
            loading={loading}
            style={{
              backgroundColor: "#3F3E3E",
              color: "white",
              borderRadius: 8,
              width: "100%",
            }}
            size="large"
            onClick={() => {
              currTab === 3
                ? handleReport(filterObj)
                : handleSearch(filterObj, 1);
            }}
          >
            ค้นหา
          </Button>
        </Col>
        {currTab === 1 ? null : (
          <Col span={1} align="right">
            <Button
              disabled
              icon="upload"
              size={"large"}
              style={{ width: "100%", height: 40, borderRadius: 8 }}
            />
          </Col>
        )}
        <Col span={24}>{selectedTabs(currTab)}</Col>
      </Row>
    </GeneralStyledContent>
  );
};

export default connect(
  ({ auth }) => ({
    auth,
  }),
  {}
)(Index);
