import React, { useState } from "react";
import { Button, Icon, Modal, Row, Col } from "antd";
const ModalDownloadVideoQueue = ({
  code,
  downloadVideoVisible,
  handleCancel,
  handleOk,
}) => {
  return (
    <Modal
      title="ลิ้งที่ถูกสร้าง"
      visible={downloadVideoVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={650}
    >
      <Row gutter={[16, 16]}>
        <Col span={24} align="center">
          {`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/videodownloadqueuevideolist/${code}`}{" "}
          <Icon
            className="download_video_queue_copy"
            type="copy"
            onClick={() => {
              navigator.clipboard.writeText(
                `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/videodownloadqueuevideolist/${code}`
              );
            }}
          />
        </Col>
        <Col span={24} align="right">
          <Button
            onClick={() => {
              handleCancel();
            }}
            type="primary"
          >
            ปิด
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalDownloadVideoQueue;
