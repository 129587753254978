import {
  LOAD_LISTVEHICLE_HONEYTOASTSTREAM,
  LOAD_VEHICLE_HONEYTOASTSTREAM,
  LOAD_DISPLAY,
  LOAD_USER_COMPANY,
  LOAD_DISPLAY_DETAIL,
  CREATE_DISPLAY,
  UPDATE_DISPLAY,
  DELETE_DISPLAY,
} from "./actions";

const initialState = {
  vehicleList: [],
  vehicleListHoneytoast: [],
  videoLoading: false,
  displayList: [],
  userList: [],
  videoList: [],
  vehicleAutoplayList: [],
  formsLoading: false,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LISTVEHICLE_HONEYTOASTSTREAM.REQUEST:
    case LOAD_VEHICLE_HONEYTOASTSTREAM.REQUEST:
    case LOAD_USER_COMPANY.REQUEST:
      // const { loading } = action.payload;
      return {
        ...state,
        videoLoading: true,
      };
    case LOAD_DISPLAY.REQUEST:
    case CREATE_DISPLAY.REQUEST:
    case UPDATE_DISPLAY.REQUEST:
    case DELETE_DISPLAY.REQUEST:
      // const { loading } = action.payload;
      return {
        ...state,
        formsLoading: true,
      };
    case LOAD_DISPLAY_DETAIL.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LOAD_LISTVEHICLE_HONEYTOASTSTREAM.SUCCESS:
      return {
        ...state,
        vehicleListHoneytoast: action.payload.data.data,
        vehicleAutoplayList: action.payload.data,
        videoLoading: false,
      };
    case LOAD_VEHICLE_HONEYTOASTSTREAM.SUCCESS:
      return {
        ...state,
        vehicleList: action.payload.data.data,
        videoLoading: false,
      };
    case LOAD_DISPLAY.SUCCESS:
      return {
        ...state,
        displayList: action.payload.data.data,
        formsLoading: false,
      };
    case LOAD_DISPLAY_DETAIL.SUCCESS:
      return {
        ...state,
        videoList: action.payload.data.data,
        loading: false,
      };
    case LOAD_USER_COMPANY.SUCCESS:
      return {
        ...state,
        userList: action.payload.data,
        videoLoading: false,
      };
    case CREATE_DISPLAY.SUCCESS:
    case UPDATE_DISPLAY.SUCCESS:
    case DELETE_DISPLAY.SUCCESS:
      return {
        ...state,
        msg: action.payload.data.message,
        status: action.payload.data.status,
        formsLoading: false,
      };

    default:
      return state;
  }
};
