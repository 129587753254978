import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

// *ดึงข้อมูลเพื่อแสดง company list
function* taxRenewalReadinessCompanyList(action) {
  const {
    data: { accessToken },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/tax-renewal-readiness/company-list`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )

    yield put(actions.taxRenewalReadinessCompanyList.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.taxRenewalReadinessCompanyList.failure())
  }
}

// *ดึงข้อมูลเพื่อแสดง log
function* taxRenewalReadinessLog(action) {
  const {
    data: {
      page,
      pageSize,
      vehicleVisibility,
      filterObj,
      canFilterCompany,
      accessToken,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/tax-renewal-readiness/log`,
      {
        page,
        pageSize,
        vehicleVisibility,
        filterObj,
        canFilterCompany,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )

    yield put(actions.taxRenewalReadinessLog.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.taxRenewalReadinessLog.failure())
  }
}

// *ดึงข้อมูลเพื่อ check dlt
function* taxRenewalReadinessCheckDlt(action) {
  const {
    data: { vehicle_id, accessToken },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/tax-renewal-readiness/check-dlt`,
      { vehicle_id },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )

    yield put(actions.taxRenewalReadinessCheckDlt.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.taxRenewalReadinessCheckDlt.failure())
  }
}

export default function* watchTaxRenewalReadiness() {
  yield all([
    takeEvery(
      actions.TAX_RENEWAL_READINESS_COMPANY_LIST.REQUEST,
      taxRenewalReadinessCompanyList
    ),
    takeEvery(
      actions.TAX_RENEWAL_READINESS_LOG.REQUEST,
      taxRenewalReadinessLog
    ),
    takeEvery(
      actions.TAX_RENEWAL_READINESS_CHECK_DLT.REQUEST,
      taxRenewalReadinessCheckDlt
    ),
  ])
}
export {
  taxRenewalReadinessCompanyList,
  taxRenewalReadinessLog,
  taxRenewalReadinessCheckDlt,
}
