import React, { Component, Fragment } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import {
  Button,
  Form,
  DatePicker,
  AutoComplete,
  Select,
  Spin,
  Badge,
  Modal,
} from "antd";
import LangContext from "modules/shared/context/langContext";
import { genDeviceProcessDetail } from "modules/monitoring/honeytoastdownloadfile/components/deviceProcessFunc";
import ModalDownloadVideoQueue from "./ModalDownloadVideoQueue";
import { getpubliclink } from "./apiClient";
const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const { Option, OptGroup } = AutoComplete;
export default class FilterAllDataTab extends Component {
  state = {
    platenolists: [],
    downloadVideoVisible: false,
    code: "",
    showGenLink: false,
  };

  handleCancel = () => {
    this.setState({ downloadVideoVisible: false });
  };
  handleOk = () => {
    this.handleCancel();
  };

  handleOpenDeviceProcessModal = () => {
    let html = genDeviceProcessDetail(this.props.deviceProcess.userList);

    Modal.warning({
      title: "อุปกรณ์นี้ กำลังมีการเชื่อมต่อการใช้งานวีดีโอดังนี้",
      content: <div dangerouslySetInnerHTML={{ __html: html }}></div>,
      okText: "ปิด",
    });
  };

  changeVehiclePlateno = (value, option, setFieldValue) => {
    setFieldValue("plate_no", value);
    setFieldValue("old_format", "");
    setFieldValue("imei", "");
    setFieldValue("vehicle_id", "");
    this.setState({
      showGenLink: false,
    });
    if (typeof option !== "undefined") {
      setFieldValue(
        "should_show_fileopt",
        this.convertToBoolean(option.props.data_otp)
      );
      setFieldValue(
        "has_file_opt",
        this.convertToBoolean(option.props.data_otp)
      );
      setFieldValue("device_types_id", option.props.data_type);
    } else {
      setFieldValue("should_show_fileopt", false);
      setFieldValue("has_file_opt", false);
      setFieldValue("device_types_id", "");
    }

    setFieldValue("file_size", "large");
    setFieldValue("camera_position", "");
  };

  selectVehiclePlateno = (value, option, setFieldValue) => {
    // console.log("selectVehiclePlateno", value, option, setFieldValue);
    setFieldValue("plate_no", option.props.data_value);
    setFieldValue("imei", option.props.label);
    setFieldValue("vehicle_id", value);
    setFieldValue("old_format", option.props.data_format);

    setFieldValue("has_file_opt", this.convertToBoolean(option.props.data_otp));
    setFieldValue(
      "should_show_fileopt",
      this.convertToBoolean(option.props.data_otp)
    );
    setFieldValue("device_types_id", option.props.data_type);
    setFieldValue("file_size", "large");
    setFieldValue("camera_position", "");
  };

  generateCameraOption = (imei) => {
    if (imei === "") {
      return this.defaultCameraOption();
    }

    let rec = this.props.vehicleOnly.find((x) => x.imei === imei);
    if (typeof rec === "undefined") {
      return this.defaultCameraOption();
    } else if (rec.camera_channel.length > 0) {
      let list = [{ index: "", name: "ทั้งหมด" }, ...rec.camera_channel];

      return list.map((x) => {
        return (
          <Option key={x.index.toString()} value={x.index.toString()}>
            {x.name}
          </Option>
        );
      });
    }

    return this.defaultCameraOption();
  };

  defaultCameraOption = () => {
    let list = [
      { value: "", label_index: "a", label: "all" },
      { value: "front", label_index: "f", label: "front" },
      { value: "back", label_index: "c", label: "camera_back" },
      { value: "extra", label_index: "c", label: "camera_extra" },
    ];

    return list.map((x) => {
      return (
        <Option value={x.value}>
          <LangContext.Consumer>
            {(i18n) => i18n[x.label_index][x.label]}
          </LangContext.Consumer>
        </Option>
      );
    });
  };

  convertToBoolean = (value) => {
    if (value === "true") {
      return true;
    }

    if (value === "false") {
      return false;
    }

    return value;
  };

  render() {
    const { onSubmit, vehicleLists, loadVehicle, deviceProcess } = this.props;
    const platenolists = vehicleLists
      .filter((x) => x.title !== "fileopt")
      .flatMap((group) =>
        group.children.map((opt) => {
          return (
            <Option
              key={opt.id}
              label={opt.imei}
              data_value={opt.plate_no}
              data_format={opt.old_format}
              data_otp={opt.has_file_opt.toString()}
              data_type={opt.device_types_id}
            >
              {opt.plate_no}
            </Option>
          );
        })
      );

    return (
      <Fragment>
        <Formik
          initialValues={{
            plate_no: "",
            eventdate: [
              moment("00:00:00", "HH:mm:ss"),
              moment("23:59:59", "HH:mm:ss"),
            ],
            camera_position: "",
            file_size: "large",
            vehicle_id: "",
            should_show_fileopt: false,
            device_types_id: "",
            has_file_opt: false,
          }}
          validate={(values) => {
            let errors = {};
            if (values.eventdate.length > 0) {
              const date_start = moment(values.eventdate[0]["_d"]);
              const date_end = moment(values.eventdate[1]["_d"]);
              let day_diff = date_end.diff(date_start, "days") + 1;
              if (day_diff > 1) {
                errors.eventdate = "ช่วงวันที่ที่เลือกไม่ควรเกิน 1 วัน";
                //console.log("ddd");
              }
            }
            //console.log("error:",errors)
            return errors;
          }}
          validationSchema={yup.object().shape({
            plate_no: yup.string().required("กรุณาเลือกทะเบียนพาหนะ"),
            eventdate: yup.array().required("กรุณาเลือกช่วงวันที่"),
          })}
          onSubmit={(values) => {
            this.setState({
              showGenLink: true,
            });
            onSubmit(true, values.vehicle_id);
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
            resetForm,
          }) => (
            <Form onSubmit={handleSubmit} layout={"inline"}>
              <FormItem
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.plateNo}
                  </LangContext.Consumer>
                }
                validateStatus={touched.plate_no && errors.plate_no && "error"}
                help={touched.plate_no && errors.plate_no}
              >
                <AutoComplete
                  dataSource={platenolists}
                  style={{ width: "300px" }}
                  optionLabelProp="children"
                  filterOption={(inputValue, option) => {
                    console.log("AutoComplete", inputValue, option);
                    if (typeof option.props.children !== "undefined") {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) !== -1
                      );
                    }
                    return false;
                  }}
                  optionFilterProp="children"
                  // placeholder="ค้นหาทะเบียน"
                  value={values.plate_no}
                  onFocus={this.props.loadData}
                  onSearch={(value, option) => {
                    setFieldValue("vehicle_id", "");
                    this.setState({
                      showGenLink: false,
                    });
                    this.changeVehiclePlateno(value, option, setFieldValue);
                  }}
                  onSelect={(value, option) =>
                    this.selectVehiclePlateno(value, option, setFieldValue)
                  }
                  notFoundContent={loadVehicle ? <Spin size="small" /> : null}
                />
              </FormItem>
              <FormItem>
                <Button htmlType="submit" type="primary">
                  <LangContext.Consumer>
                    {(i18n) => i18n.a.apply}
                  </LangContext.Consumer>
                </Button>
              </FormItem>
              {this.state.showGenLink ? (
                <FormItem>
                  <Button
                    onClick={() => {
                      getpubliclink(
                        {
                          vehicle_id: values.vehicle_id,
                          user_id: this.props.userId,
                        },
                        (status, res) => {
                          this.setState({
                            downloadVideoVisible: true,
                            code: res.data.data,
                          });
                        }
                      );
                    }}
                    type="primary"
                    disabled={this.props.generateLinkStatus}
                  >
                    <LangContext.Consumer>
                      {(i18n) => i18n.c.createLink}
                    </LangContext.Consumer>
                  </Button>
                </FormItem>
              ) : null}

              <ModalDownloadVideoQueue
                handleOk={this.handleOk}
                handleCancel={this.handleCancel}
                downloadVideoVisible={this.state.downloadVideoVisible}
                code={this.state.code}
              />
            </Form>
          )}
        />
      </Fragment>
    );
  }
}
