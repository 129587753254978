import { createTypes, createAction } from 'lib/action'

const LOAD_TAXIFARE = createTypes('taxiFare', 'load')

const LOAD_VEHICLE_TAXIFARE = createTypes('vehicle_taxifare', 'load')
const LOAD_VEHICLE_TYPE_TAXIFARE = createTypes(
  'vehicletype_taxifare',
  'load'
)

const loadTaxiFare = {
  request: (data, loading) =>
    createAction(LOAD_TAXIFARE.REQUEST, { data, loading }),
  success: (data) => createAction(LOAD_TAXIFARE.SUCCESS, { data }),
  failure: () => createAction(LOAD_TAXIFARE.FAILURE),
}

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_TAXIFARE.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: (data) => createAction(LOAD_VEHICLE_TAXIFARE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TAXIFARE.FAILURE),
}

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_TAXIFARE.REQUEST, {}),
  success: (data) => createAction(LOAD_VEHICLE_TYPE_TAXIFARE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_TAXIFARE.FAILURE),
}

export {
  loadTaxiFare,
  LOAD_TAXIFARE,
  loadVehicle,
  LOAD_VEHICLE_TAXIFARE,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_TAXIFARE,
}
