import React, { useState, useEffect } from "react";
import { Row, Col, Icon, Badge, Modal, Button, Spin, Select } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { driverNoti } from "./Constant";

const { Option } = Select;

const ModalDriverNoti = ({ visibleDriverNoti, onCloseDriverNoti }) => {
  const [driverNotification, setDriverNotification] = useState({
    driver_status: 0,
    duration: 0,
  });

  const handlePutDriverNoti = () => {
    onCloseDriverNoti();
  };

  return (
    <Spin spinning={false}>
      <Modal
        title={
          <Row>
            <Col span={24} align="left">
              แจ้งเตือนผู้ขับ
            </Col>
          </Row>
        }
        onCancel={() => {
          onCloseDriverNoti();
        }}
        footer={null}
        visible={visibleDriverNoti}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>สถานะผู้ขับ</Col>
          <Col span={24}>
            <Select
              className="dashboard__adas_selected"
              defaultValue={1}
              style={{ width: "100%" }}
              size="large"
              onChange={(val) => {
                setDriverNotification(val);
              }}
            >
              {driverNoti.driver_status.map((item) => (
                <Option value={item.id}>{item.label}</Option>
              ))}
            </Select>
          </Col>
          <Col span={24}>ระยะเวลาจอดพัก</Col>
          <Col span={24}>
            <Select
              className="dashboard__adas_selected"
              defaultValue={1}
              style={{ width: "100%" }}
              size="large"
            >
              {driverNoti.duration.map((item) => (
                <Option value={item.id}>{item.timing} นาที</Option>
              ))}
            </Select>
          </Col>
          <Col span={24} align="right">
            <Button
              style={{
                backgroundColor: "#3F3E3E",
                color: "white",
                borderRadius: 8,
              }}
              size="large"
              onClick={() => {
                handlePutDriverNoti();
              }}
            >
              ยืนยัน
            </Button>
          </Col>
        </Row>
      </Modal>
    </Spin>
  );
};

export default ModalDriverNoti;
