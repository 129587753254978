import React from "react";
import LangContext from "modules/shared/context/langContext";
import * as moment from "moment";

const queueStatusId = (key) => {
  switch (key) {
    case "waiting":
      return 1;
    case "downloading":
      return 2;
    case "complete":
      return 3;
    case "cancel":
      return 4;
    case "error":
      return 5;
    default:
      return 0;
  }
};

const getQueueStatus = (status, remark) => {
  switch (status) {
    case 1:
      return (
        <LangContext.Consumer>{(i18n) => i18n.w.waiting}</LangContext.Consumer>
      );
    case 2:
      return (
        <LangContext.Consumer>
          {(i18n) => i18n.d.downloading}
        </LangContext.Consumer>
      );
    case 3:
      return (
        <LangContext.Consumer>
          {(i18n) => i18n.f.fileOnServer}
        </LangContext.Consumer>
      );
    case 4:
      return (
        <LangContext.Consumer>{(i18n) => i18n.c.cancel}</LangContext.Consumer>
      );
    case 5:
      if (remark) {
        return <span style={{ fontSize: "9px" }}>{remark}</span>;
      }
      return (
        <LangContext.Consumer>{(i18n) => i18n.e.error}</LangContext.Consumer>
      );
    default:
      return "อยู่ใน SDCard อุปกรณ์";
  }
};

const getCameraText = (source, type) => {
  // icing
  if (parseInt(type) === 40) {
    return `ch${parseInt(source) - 1}`;
  }

  switch (source) {
    case "front":
    case 1:
      return (
        <LangContext.Consumer>{(i18n) => i18n.f.front}</LangContext.Consumer>
      );
    case "back":
    case 2:
      return (
        <LangContext.Consumer>
          {(i18n) => i18n.c.camera_back}
        </LangContext.Consumer>
      );
    case "extra":
    case 3:
      return (
        <LangContext.Consumer>
          {(i18n) => i18n.c.camera_extra}
        </LangContext.Consumer>
      );
    default:
      return `ch${parseInt(source) - 1}`;
  }
};

const convertQueueStatus = (key) => {
  let status = "ERROR";
  switch (key) {
    case "PROGRESS":
      status = "DOWNLOADING";
      break;
    case "COMPLETE":
      status = "COMPLETE";
      break;
    default:
      break;
  }
  return status;
};

const downloadFileVideo = (url) => {
  let pattern = /http/;
  if (pattern.test(url)) {
    window.open(url);
  } else {
    let pathFile = url
      .split("/")
      .slice(1)
      .join("/");
    let urlStr =
      `https://${process.env.REACT_APP_WEBSOCKET}/downloadvideo?path_file=` +
      `http://localhost/` +
      pathFile;
    window.open(urlStr);
  }
};

const headers = (accessToken) => {
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

const arraysEqual = (old, nw) => {
  if (old.length !== nw.length) {
    return true;
  }

  return old.every((o, idx) => objectsEqual(o, nw[idx]));
};

const objectsEqual = (o1, o2) => {
  if (Object.is(o1, null) && Object.is(o2, null)) {
    return true;
  }

  if (Object.is(o1, null) || Object.is(o2, null)) {
    return false;
  }

  if (typeof o1 === "object") {
    if (Object.keys(o1).length > 0) {
      return (
        Object.keys(o1).length === Object.keys(o2).length &&
        Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
      );
    }
  }
  return o1 === o2;
};

const genListFile = (
  data,
  imei,
  plate_no,
  vehicles_id,
  old_format,
  queuelist
) => {
  let newlistFiles = [];
  let d = data.files;

  for (let i = 0; i < d.length; i++) {
    let size = d[i].size / 1024 / 1024;
    let date_time = moment(d[i].create_date).format("YYYY-MM-DD HH:mm:ss");

    let btn_disable = d[i].size > 0 ? false : true;
    let queue_status = 0;
    let download_link = "";
    let download_progress = 0;
    let queue_id = 0;

    let info = queuelist.find((x) => x.hwid == imei && x.filename == d[i].name);

    if (typeof info !== "undefined") {
      queue_status = info.queue_status;
      download_link = info.download_link;
      download_progress = info.download_progress;
      queue_id = info.id;
    }

    newlistFiles.push({
      index: i,
      row_number: i + 1,
      plate_no: plate_no,
      file_datetime: date_time,
      size: size.toFixed(2) + " MB",
      filename: d[i].name,
      hwid: imei,
      camera_source: d[i].camera_source,
      camera_source_idx: getCameraIndex(d[i].camera_source),
      vehicle_id: vehicles_id,
      original_size: d[i].size,
      original_date: d[i].create_date,
      btn_disable: btn_disable,
      queue_status,
      download_link,
      download_progress,
      queue_id,
      old_format,
    });
  }

  return newlistFiles;
};

const getCameraIndex = (name) => {
  switch (name) {
    case "front":
      return 1;
    case "back":
      return 2;
    case "extra":
      return 3;
    default:
      return name;
  }
};

const updateFileList = (oldList, index, update) => {
  if (index === -1) {
    return oldList;
  }

  return oldList.map((x, idx) => {
    if (index === idx) {
      return { ...x, ...update };
    }
    return x;
  });
};

const onlyUnique = (value, index, array) => {
  return array.indexOf(value) === index;
};

export {
  getQueueStatus,
  getCameraText,
  convertQueueStatus,
  downloadFileVideo,
  headers,
  arraysEqual,
  genListFile,
  updateFileList,
  queueStatusId,
  onlyUnique,
};
