import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Empty } from 'antd'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import moment from 'moment'
// import { scaleTime } from 'd3-scale'
// import { timeFormat } from 'd3-time-format'
// import { timeHour, timeDay } from 'd3-time'

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Brush,
} from 'recharts'

class TimeChart extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  customTickX = (e) => {
    // จัดรูปแบบแสดงผลแค่เวลา HH:mm เท่านั้น
    if (
      e.payload.value === undefined ||
      e.payload.value === null ||
      e.payload.value === ''
    ) {
      return null
    }

    let time = moment(e.payload.value).format('HH:mm')

    return (
      <text x={e.x} y={e.y} dy={10} textAnchor='middle' fill='#666'>
        {time}
      </text>
    )
  }

  customTickY = (e) => {
    // ถ้าเป็น category = ch ให้แสดงข้อความว่าเวลาของวิดีโอ
    let text = ''
    let category_index = this.props.all_category
    let category = category_index[e.payload.value - 1]
    if (category) {
      if (category.startsWith('CH')) {
        let textArray = category.split('_')
        text = (
          <>
            <tspan>{`${textArray[0]}`}</tspan>
            <tspan x={e.x} dy={15}>{`(${textArray[1]})`}</tspan>
          </>
        )
      } else if (category === 'GPS') {
        text = 'GPS'
      } else {
        text = ''
      }
    }

    return (
      <text x={e.x} y={e.y} dy={5} textAnchor='middle' fill='#666'>
        {text}
      </text>
    )
  }

  // *customize tooltip in line chart
  customTooltip = ({ active, payload, label }) => {
    let chart_data = this.props.chart_data
    let chart_color = this.props.chart_color
    if (active && payload && payload.length) {
      let created_at_unix = payload[0].payload.created_at_unix
      let data_for_display = chart_data.filter((item) => {
        if (item.category === 'a1' || item.category === 'a2') {
          return false
        } else {
          if (item.category === 'GPS') {
            return item.created_at_unix === created_at_unix
          } else if (item.category.startsWith('CH')) {
            // ถ้าเป็นข้อมูลอื่นๆ ให้เอาข้อมูลที่ created_at_unix
            return (
              item.start_at_unix <= created_at_unix &&
              item.end_at_unix >= created_at_unix &&
              item.type === 'start'
            )
          } else {
            return false
          }
        }
      })

      // sort by category asc
      data_for_display.sort((a, b) => {
        return a.category > b.category ? 1 : -1
      })

      if (data_for_display.length === 0) {
        return null
      } else {
        return (
          <div className='custom-tooltip'>
            {data_for_display.map((item, index) => {
              return (
                <p
                  key={index}
                  style={{
                    color: chart_color.find(
                      (item2) => item2.category === item.category
                    )
                      ? chart_color.find(
                          (item2) => item2.category === item.category
                        ).color
                      : '#000',
                  }}
                >
                  {item.category.startsWith('CH')
                    ? `${item.category} : ${item.start_at_display} - ${item.end_at_display}`
                    : item.category === 'GPS'
                    ? `${i18n[this.props.language].g.gpsTime} : ${
                        item.created_at
                      }`
                    : ''}
                </p>
              )
            })}
          </div>
        )
      }
    }

    return null
  }

  render() {
    const {
      chart_color,
      chart_data,
      chart_ticks_x,
      chart_ticks_y,
      all_category,
    } = this.props

    //   !สำหรับแบ่ง scale ออกเป็น 24 ช่วง วิธีนี้ใช้ได้เหมือนกัน
    // const now = new Date()
    // const domainToday = scaleTime().domain([
    //   timeDay.floor(now),
    //   timeDay.ceil(now),
    // ])
    // const timeFormatter = tick => {
    //   return timeFormat('%H:%M')(new Date(tick))
    // }
    // const ticks = domainToday.ticks(timeHour.every(1))

    return (
      <Fragment>
        {chart_data && chart_data.length > 0 ? (
          <>
            <div className='line-chart-detail'>
              <ResponsiveContainer aspect={3}>
                <LineChart
                  width={'100%'}
                  height={'100%'}
                  data={chart_data}
                  //   onClick={e => {}}
                  margin={{
                    top: 20,
                    right: 20,
                    left: 20,
                    bottom: 20,
                  }}
                >
                  <CartesianGrid
                    fill='#FFFFFF'
                    horizontal={true}
                    vertical={true}
                  />
                  <XAxis
                    dataKey='created_at_unix'
                    allowDuplicatedCategory={false}
                    domain={['dataMin', 'dataMax']}
                    type='number'
                    ticks={chart_ticks_x}
                    tickFormatter={(tick) => moment(tick).format('HH:mm')}
                  />
                  <YAxis
                    type='category_index'
                    dataKey='category_index'
                    tickLine={false}
                    ticks={chart_ticks_y}
                    tick={this.customTickY}
                    tickMargin={18}
                    interval={0}
                  />
                  <Tooltip content={this.customTooltip} />

                  <Line
                    key={1}
                    type='monotone'
                    data={chart_data}
                    dataKey={1}
                    stroke={null}
                    fill={null}
                    dot={false}
                  />
                  {/* ถ้าเป็นข้อมูล GPS ให้แสดงแบบไม่มีเส้นเชื่อม */}
                  <Line
                    key={all_category.indexOf('GPS') + 1}
                    type='linear'
                    dataKey={all_category.indexOf('GPS') + 1}
                    data={chart_data}
                    stroke={null}
                    fill={
                      chart_color.find((item) => item.category === 'GPS')
                        ? chart_color.find((item) => item.category === 'GPS')
                            .color
                        : '#000'
                    }
                    dot={true}
                  />

                  {/* ถ้าเป็นข้อมูลวิดีโอให้แสดงเส้นเชื่อมเป็นคู่ start กับ end */}
                  {chart_data.map((item, index) => {
                    if (item.category.startsWith('CH') && item.type === 'end') {
                      let index_start = chart_data.findIndex(
                        (item2) =>
                          item2.type === 'start' &&
                          item2.get_list_vdo_id === item.get_list_vdo_id
                      )
                      if (index_start !== -1) {
                        return (
                          <Line
                            key={item.get_list_vdo_id}
                            data={[chart_data[index_start], item]}
                            type='linear'
                            dataKey={all_category.indexOf(item.category) + 1}
                            stroke={
                              chart_color.find(
                                (item2) => item2.category === item.category
                              )
                                ? chart_color.find(
                                    (item2) => item2.category === item.category
                                  ).color
                                : '#000'
                            }
                            fill={
                              chart_color.find(
                                (item2) => item2.category === item.category
                              )
                                ? chart_color.find(
                                    (item2) => item2.category === item.category
                                  ).color
                                : '#000'
                            }
                            dot={true}
                          />
                        )
                      } else {
                        return null
                      }
                    } else {
                      return null
                    }
                  })}

                  <Line
                    key={all_category.length}
                    type='monotone'
                    data={chart_data}
                    dataKey={all_category.length}
                    stroke={null}
                    fill={null}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </>
        ) : (
          <>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </>
        )}
      </Fragment>
    )
  }
}

export default connect(
  ({ ui: { language } }) => ({
    language,
  }),
  {}
)(TimeChart)
