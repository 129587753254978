import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Spin } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import ReactPlayer from "react-player";

const ModalVideo = ({ visibleVideo, vehicleId, onCloseModalVideo }) => {
  useEffect(() => {}, [visibleVideo]);

  return (
    <Spin spinning={false}>
      <Modal
        title={
          <Row>
            <Col span={24} align="center">
              <LangContext.Consumer>
                {(i18n) => i18n.v.videos}
              </LangContext.Consumer>
            </Col>
          </Row>
        }
        onCancel={() => {
          onCloseModalVideo();
        }}
        width={window.innerWidth - 100}
        footer={null}
        visible={visibleVideo}
        style={{ top: 10 }}
      >
        <div
          style={{
            borderRadius: 8,
            height: window.innerHeight - 150,
          }}
        >
          <ReactPlayer
            url={"https://youtu.be/JbAv9mK2iAY?si=7Ew7N6pbsgKxVRH2"}
            controls={true}
            playing={true}
            muted={true} // Mute the video to bypass autoplay restrictions
            width="100%"
            height="100%"
          />
        </div>
      </Modal>
    </Spin>
  );
};

export default ModalVideo;
