import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Tabs, Modal } from "antd";
import * as moment from "moment";

import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "./../../../shared/components/Breadcrumb";
import LangContext from "modules/shared/context/langContext";
import ChooseFile from "./ChooseFile";
import AllData from "./AllData";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";

import * as helper from "./helperFx";
import {
  fetchData,
  createQueue,
  cancelQueue,
  reQueue,
  deleteQueue,
  fetchAllConnection,
  fetchDeviceProcess,
  createQueues,
} from "./apiClient";

const { TabPane } = Tabs;
class Index extends Component {
  state = {
    idprocess: "web" + new Date().getTime(),
    loading: false,
    queues: [],
    listfiles: [],
    updateAt: "",
    deviceProcess: null,
    idconnect: "",
    filter: {
      vehicle_id: "",
      plate_no: "",
      cameraPosition: "",
      daterange: "",
      date_start: "",
      date_end: "",
      old_format: false,
    },
    vehicle_id_alldata: "",
  };

  connectWebsocket = () => {
    this.ws = new WebSocket(
      `wss://${process.env.REACT_APP_WEBSOCKET}/ws?idprocess=` +
        this.state.idprocess
    );

    this.ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      console.log("connected websocket htn");
    };

    this.ws.onmessage = (evt) => {
      // listen to data sent from the websocket server
      // const message = JSON.parse(evt.data)
      this.handleReceiveWebsocketMsg(evt);
    };

    this.ws.onclose = (e) => {
      console.log(
        "Socket is closed. Reconnect will be attempted in 1 second." + e.code
      );
      setTimeout(() => {
        this.connectWebsocket();
      }, 1000);
    };

    this.ws.onerror = (err) => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );
      this.ws.close();
    };
  };

  handleReceiveWebsocketMsg = (evt) => {
    var messages = evt.data.split("\n");

    for (var i = 0; i < messages.length; i++) {
      let obj = JSON.parse(messages[i]);
      let imei =
        typeof obj.Idprocess === "undefined" ? obj.IdProcess : obj.Idprocess;
      let connectId =
        typeof obj.Idconnect === "undefined" ? obj.IdConnect : obj.Idconnect;

      if (imei === "" && connectId === "") {
        continue;
      }

      let data = JSON.parse(obj.Data);
      console.log("data", data);
      if (typeof data.type !== "undefined") {
        //old firmware, new firmware
        if (["get_list_video", "getListVideo"].includes(data.type)) {
          const { plate_no, vehicle_id, old_format } = this.state.filter;
          console.log("first", this.state.filter);
          let listfile = helper.genListFile(
            data,
            imei,
            plate_no,
            vehicle_id,
            old_format,
            this.state.queues
          );
          this.setState(
            {
              listfiles: listfile,
            },
            () => {
              this.setState({ loading: false });
            }
          );
        }
      }
    }
  };

  handleLoadQueue = async (showloading, vehicle_id) => {
    if (showloading) {
      this.setState({ loading: true, vehicle_id_alldata: vehicle_id });
    }

    console.log("handleLoadQueue", showloading, vehicle_id);

    let params = {
      vehicle_visibility: this.props.auth.profile.vehicle_visibility,
      company_id: this.props.auth.profile.company_id,
      vehicles_id: vehicle_id === undefined ? "" : vehicle_id,
      start_at: "",
      end_at: "",
      camera_source: "",
      plate_no: "",
    };

    fetchData(params, (statusCode, response) => {
      if (statusCode !== 200) {
        this.setState({ loading: false, queues: [] });
        return;
      }
      //debugger
      let updateList = this.state.listfiles.map((x) => {
        //let nw = response.find(r => r.id === x.queue_id)
        let nw = response.find(
          (r) =>
            r.filename === x.filename &&
            r.hwid == x.hwid &&
            r.camera_source.toString() == x.camera_source_idx.toString()
        );
        if (typeof nw !== "undefined") {
          return {
            ...x,
            queue_id: nw.id,
            download_progress: nw.download_progress,
            queue_status: nw.queue_status,
            download_link: nw.download_link,
          };
        }
        // case data โดนลบ
        if (x.queue_id !== 0) {
          return {
            ...x,
            queue_id: 0,
            download_progress: 0,
            queue_status: 0,
            download_link: "",
          };
        }
        return x;
      });

      this.setState({
        loading: false,
        queues: response,
        updateAt: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        listfiles: updateList,
      });
    });
  };

  handleLoadListFileFromDevice = (values) => {
    const {
      imei,
      eventdate,
      plate_no,
      camera_position,
      vehicle_id,
      file_size,
      old_format,
    } = values;

    const date_start = moment(eventdate[0]["_d"]).format("YYYY-MM-DD HH:mm:ss");
    const date_end = moment(eventdate[1]["_d"]).format("YYYY-MM-DD HH:mm:ss");

    let data = {
      date_start: date_start,
      date_end: date_end,
      camera_source: camera_position,
      file_size: file_size,
    };

    let message = {
      Idconnect: imei,
      Idprocess: this.state.idprocess,
    };

    // if (!old_format) {
    //   data.type = "getListVideo"
    //   message.Key = ""
    //   message.CmdType = "getListVideo"
    //   message.Data = JSON.stringify(data)
    //   console.log("new firmware", message)
    //   this.ws.send(JSON.stringify(message));
    // }

    // if (old_format) {
    data.type = "get_list_video";
    message.Data = JSON.stringify(data);
    console.log("old firmware", message);
    this.ws.send(JSON.stringify(message));
    // }

    fetchDeviceProcess(imei, (data) => {
      const { cntDownload, cntLivestream, cntPlayback } = data;
      let total = cntDownload + cntLivestream + cntPlayback;
      let result = data;
      if (total === 0) {
        result = null;
      }

      let filter = {
        vehicle_id: vehicle_id,
        plate_no: plate_no,
        cameraPosition: camera_position,
        file_size: file_size,
        daterange: date_start + " - " + date_end,
        date_start,
        date_end,
        old_format,
      };

      console.log("filter", filter);
      this.setState(
        {
          loading: true,
          listfiles: [],
          deviceProcess: result,
          idconnect: imei,
          filter: filter,
        },
        this.handleCheckProcess
      );
    });
  };

  handleCreateQueue = async (record) => {
    this.setState({ loading: true });
    const params = {
      vehicles_id: parseInt(record.vehicle_id),
      hwid: record.hwid,
      filename: record.filename,
      file_size: parseInt(record.original_size),
      file_datetime: record.original_date,
      camera_source: record.camera_source,
      queue_status: helper.queueStatusId("downloading"),
      user_id: this.props.auth.profile.id,
      id_process: this.state.idprocess,
      file_option: this.state.filter.file_size,
    };

    fetchAllConnection((allimei) => {
      if (allimei.includes(record.hwid)) {
        createQueue(params, this.handleCallbackQueueAction);
      } else {
        Modal.error({
          title: "แจ้งเตือน",
          content: "ไม่สามารถดาวน์โหลดได้ เนื่องจากอุปกรณ์ออฟไลน์อยู่",
        });
        this.setState({ loading: false });
      }
    });
  };

  handleLoadMultiple = async (selectRows) => {
    let items = [];
    let hwid = "";
    this.setState({ loading: true });
    this.state.listfiles.map((record) => {
      if (
        selectRows.includes(
          record.filename + "_" + record.camera_source.toString()
        )
      ) {
        hwid = record.hwid;
        const params = {
          vehicles_id: parseInt(record.vehicle_id),
          hwid: record.hwid,
          filename: record.filename,
          file_size: parseInt(record.original_size),
          file_datetime: record.original_date,
          camera_source: record.camera_source,
          queue_status: helper.queueStatusId("downloading"),
          user_id: this.props.auth.profile.id,
          id_process: this.state.idprocess,
          file_option: this.state.filter.file_size,
        };

        items = [...items, params];
      }
      return;
    });

    fetchAllConnection((allimei) => {
      if (allimei.includes(hwid)) {
        createQueues({ data: items }, () => {
          this.handleLoadQueue(true, this.state.vehicle_id_alldata);
        });
      } else {
        Modal.error({
          title: "แจ้งเตือน",
          content: "ไม่สามารถดาวน์โหลดได้ เนื่องจากอุปกรณ์ออฟไลน์อยู่",
        });
        this.setState({ loading: false });
      }
    });
  };

  handleReQueue = async (queueId, hwid, filename) => {
    this.setState({ loading: true });
    let params = {
      id: queueId,
      hwid,
      filename,
      queue_status: helper.queueStatusId("downloading"),
      user_id: this.props.auth.profile.id,
      id_process: this.state.idprocess,
    };

    fetchAllConnection((allimei) => {
      if (allimei.includes(hwid)) {
        reQueue(params, this.handleCallbackQueueAction);
      } else {
        Modal.error({
          title: "แจ้งเตือน",
          content: "ไม่สามารถดาวน์โหลดได้ เนื่องจากอุปกรณ์ออฟไลน์อยู่",
        });
        this.setState({ loading: false });
      }
    });
  };

  handleCancelQueue = async (queueId, hwid, filename) => {
    this.setState({ loading: true });
    let params = {
      id: queueId,
      hwid,
      filename,
      queue_status: helper.queueStatusId("cancel"),
      user_id: this.props.auth.profile.id,
      id_process: this.state.idprocess,
    };

    fetchAllConnection((allimei) => {
      if (allimei.includes(hwid)) {
        cancelQueue(params, this.handleCallbackQueueAction);
      } else {
        Modal.error({
          title: "แจ้งเตือน",
          content: "ไม่สามารถยกเลิกได้ เนื่องจากอุปกรณ์ออฟไลน์อยู่",
        });
        this.setState({ loading: false });
      }
    });
  };

  handleDeleteQueue = (queueId, hwid, filename, editMode = false) => {
    this.setState({ loading: true });
    let params = {
      id: queueId,
      hwid,
      filename,
      queue_status: 0,
      user_id: this.props.auth.profile.id,
      id_process: this.state.idprocess,
    };
    deleteQueue(
      params,
      editMode == false
        ? (statusCode, response) => {
            if (statusCode !== 200) {
              this.setState({ loading: false });
              return;
            }

            this.setState((prevState) => {
              let filename = response.data.params.filename;
              let hwid = response.data.params.hwid;

              let newData = prevState.listfiles.filter(
                (x) => !(x.filename == filename && x.hwid == hwid)
              );
              let newQueue = prevState.queues
                .filter((x) => !(x.filename == filename && x.hwid == hwid))
                .map((x, idx) => {
                  x.row_number = idx + 1;
                  return x;
                });

              return {
                loading: false,
                listfiles: newData,
                queues: newQueue,
              };
            });
          }
        : this.handleCallbackQueueAction
    );
  };

  handleCallbackQueueAction = (statusCode, response) => {
    if (statusCode !== 200) {
      this.setState({ loading: false });
      return;
    }

    const { params, msg, download_link, data } = response.data;

    if (msg === "file exists") {
      window.location = download_link;
      return;
    }

    let updateData = {
      download_progress: 0,
      queue_status: params.queue_status,
      download_link: "",
    };

    /// case create
    if (typeof data !== "undefined") {
      updateData.queue_id = data.id;
    }

    let filename = params.filename;
    let imei = params.hwid;

    let index = this.state.listfiles.findIndex(
      (x) =>
        x.filename == filename &&
        x.hwid == imei &&
        x.camera_source == params.camera_source
    );
    let newData = helper.updateFileList(
      this.state.listfiles,
      index,
      updateData
    );

    let newQueue = [];
    if (params.queue_status === 0) {
      // delete case
      newQueue = this.state.queues.filter(
        (x) => !(x.filename == filename && x.hwid == imei)
      );
    } else {
      index = this.state.queues.findIndex(
        (x) => x.filename == filename && x.hwid == imei
      );
      newQueue = helper.updateFileList(this.state.queues, index, updateData);
    }

    newQueue = newQueue.map((x, idx) => {
      x.row_number = idx + 1;
      return x;
    });

    this.setState({
      loading: false,
      listfiles: newData,
      queues: newQueue,
    });
  };

  handleCheckProcess = () => {
    if (this.state.idconnect === "") {
      return;
    }

    fetchDeviceProcess(this.state.idconnect, (data) => {
      const { cntDownload, cntLivestream, cntPlayback } = data;
      let total = cntDownload + cntLivestream + cntPlayback;
      let result = data;
      if (total === 0) {
        result = null;
      }
      this.setState({
        deviceProcess: result,
      });
    });
  };

  componentDidMount() {
    this.handleLoadQueue(true, this.state.vehicle_id_alldata);
    this.connectWebsocket();

    this.interval = setInterval(() => {
      this.handleLoadQueue(false, this.state.vehicle_id_alldata);
      this.handleCheckProcess();
    }, 1000 * 15);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <AuthorizeComponent matching_name="download_video_honeytoast">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={12}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row gutter={24} type="flex">
            <Col span={24}>
              <LangContext>
                {(i18n) => (
                  <Tabs defaultActiveKey="1">
                    <TabPane tab={i18n.s.searchFile} key="1">
                      <ChooseFile
                        loading={this.state.loading}
                        dataSource={this.state.listfiles}
                        onSubmit={this.handleLoadListFileFromDevice}
                        deviceProcess={this.state.deviceProcess}
                        handleLoadMultiple={this.handleLoadMultiple}
                        action={{
                          create: this.handleCreateQueue,
                          requeue: this.handleReQueue,
                          cancel: this.handleCancelQueue,
                          delete: this.handleDeleteQueue,
                        }}
                      />
                    </TabPane>
                    <TabPane tab={i18n.f.fileOnServer} key="2">
                      <AllData
                        dataSource={this.state.queues}
                        loading={this.state.loading}
                        updateAt={this.state.updateAt}
                        onSearch={this.handleLoadQueue}
                        action={{
                          requeue: this.handleReQueue,
                          cancel: this.handleCancelQueue,
                          delete: this.handleDeleteQueue,
                        }}
                        //new prop
                        onSubmit={this.handleLoadListFileFromDevice}
                        deviceProcess={this.state.deviceProcess}
                      />
                    </TabPane>
                  </Tabs>
                )}
              </LangContext>
            </Col>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
});

export default connect(mapStateToProps, {})(Index);
