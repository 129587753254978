import {
  TAX_RENEWAL_READINESS_COMPANY_LIST,
  TAX_RENEWAL_READINESS_LOG,
  TAX_RENEWAL_READINESS_CHECK_DLT,
  REMOVE_LISTS,
} from './actions'

const initialState = {
  // *ดึงข้อมูล company list
  check_company_list: false,
  company_list: [],

  // *ดึงข้อมูล log
  check_log: false,
  log_list: [],

  // *check dlt
  check_dlt: false,
  dlt_list: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState
    case TAX_RENEWAL_READINESS_COMPANY_LIST.REQUEST:
      return {
        ...state,
        check_company_list: true,
        company_list: [],
      }
    case TAX_RENEWAL_READINESS_COMPANY_LIST.SUCCESS:
      return {
        ...state,
        company_list: action.payload.lists || [],
        check_company_list: false,
      }

    case TAX_RENEWAL_READINESS_LOG.REQUEST:
      return {
        ...state,
        check_log: true,
        log_list: [],
      }
    case TAX_RENEWAL_READINESS_LOG.SUCCESS:
      return {
        ...state,
        log_list: action.payload.lists || [],
        check_log: false,
      }

    case TAX_RENEWAL_READINESS_CHECK_DLT.REQUEST:
      return {
        ...state,
        check_dlt: true,
        dlt_list: [],
      }
    case TAX_RENEWAL_READINESS_CHECK_DLT.SUCCESS:
      return {
        ...state,
        dlt_list: action.payload.lists || [],
        check_dlt: false,
      }

    default:
      return state
  }
}
