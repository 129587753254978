import {
  LOAD_ALARM_EVENT,
  LOAD_ADAS_EVENT_REPORT,
  LOAD_VEHICLE_TYPE_WITH_VALUE,
  LOAD_ADAS_VEHICLE,
} from "./actions";

const initialState = {
  loading: false,
  alarmEvent: [],
  vehicleTypeLists: [],
  vehicleLists: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ALARM_EVENT.REQUEST:
      return {
        ...state,
        loading: true,
        alarmEvent: [],
      };
    case LOAD_ADAS_EVENT_REPORT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_VEHICLE_TYPE_WITH_VALUE.REQUEST:
      const { loading_vehicle2 } = action.payload;
      return {
        ...state,
        eventLoading: loading_vehicle2,
      };
    case LOAD_ADAS_VEHICLE.REQUEST:
      return {
        ...state,
        vehicleLists: [],
      };

    case LOAD_ALARM_EVENT.SUCCESS:
      return {
        ...state,
        alarmEvent: action.payload.data.data,
        loading: false,
      };
    case LOAD_ADAS_EVENT_REPORT.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case LOAD_VEHICLE_TYPE_WITH_VALUE.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
        eventLoading: false,
      };
    case LOAD_ADAS_VEHICLE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
      };
    case LOAD_ALARM_EVENT.FAILURE:
    case LOAD_ADAS_EVENT_REPORT.FAILURE:
    case LOAD_ADAS_VEHICLE.REQUEST:
      return {
        loading: false,
        data: [],
        msg: action.payload.error,
      };
    default:
      return state;
  }
};
