import {
  LOAD_TAXIFARE,
  LOAD_VEHICLE_TAXIFARE,
  LOAD_VEHICLE_TYPE_TAXIFARE,
} from './actions'

const initialState = {
  taxifareUrl: '',
  taxifareLoading: false,
  vehicleLists: [],
  vehicleTypeLists: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TAXIFARE.REQUEST:
      const { loading } = action.payload
      return {
        ...state,
        taxifareLoading: loading,
      }
    case LOAD_TAXIFARE.SUCCESS:
      return {
        ...state,
        taxifareUrl: action.payload.data.data,
        taxifareLoading: false,
      }
    case LOAD_VEHICLE_TAXIFARE.REQUEST:
      const { loading_vehicle } = action.payload
      return {
        ...state,
        temperatureLoading: loading_vehicle,
      }
    case LOAD_VEHICLE_TAXIFARE.SUCCESS:
      return {
        ...state,
        vehicleLists: action.payload.data.data,
        temperatureLoading: false,
      }
    case LOAD_VEHICLE_TYPE_TAXIFARE.SUCCESS:
      return {
        ...state,
        vehicleTypeLists: action.payload.data.data,
      }
    default:
      return state
  }
}
