import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import AuthorizeComponent from '../../../auth/components/AuthorizeComponent'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import moment from 'moment'

import { Row, Col, Spin, PageHeader, Table } from 'antd'

// Components
import TimeChart from './TimeChart'

// API
import { monitorGPSChartLog, removeLists } from '../actions'

// CSS
import '../assets/css/index.css'

class ViewDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //*ข้อมูลกราฟ
      chart_title: '',
      chart_data: [],
      chart_ticks: [],
      ticket_data: [],

      chart_color: [
        {
          category: 'ticket',
          color: '#00b4d8',
        },
        {
          category: 'gps',
          color: '#00D090',
        },
      ],
    }
    this.columns = [
      {
        title: i18n[this.props.language].n.noReport,
        dataIndex: 'ticket_id',
        key: 'ticket_id',
        align: 'center',
        width: 50,
        render: (text, record, index) => {
          return (
            <>
              <span>{index + 1}</span>
            </>
          )
        },
      },
      // date
      {
        title: i18n[this.props.language].d.date,
        dataIndex: 'ticket_id',
        key: 'ticket_id',
        align: 'center',
        width: 300,
        render: (text, record, index) => {
          return (
            <>
              <span>
                {`${
                  record.load_start_at
                    ? moment(record.load_start_at).format('DD/MM/YYYY HH:mm:ss')
                    : ''
                } - ${
                  record.plant_move_in2_at
                    ? moment(record.plant_move_in2_at).format(
                        'DD/MM/YYYY HH:mm:ss'
                      )
                    : ''
                }`}
              </span>
            </>
          )
        },
      },
      // first_discharge_at
      {
        title: i18n[this.props.language].f.firstDischargeAt,
        dataIndex: 'first_discharge_at',
        key: 'first_discharge_at',
        align: 'center',
        width: 200,
        render: (text, record, index) => {
          return (
            <>
              <span>
                {record.first_discharge_at
                  ? moment(record.first_discharge_at).format(
                      'DD/MM/YYYY HH:mm:ss'
                    )
                  : ''}
              </span>
            </>
          )
        },
      },
      // site name
      {
        title: i18n[this.props.language].s.site_name,
        dataIndex: 'sdr_cust_id',
        key: 'sdr_cust_id',
        align: 'center',
        render: (text, record, index) => {
          return (
            <>
              <span>{record.sdr_cust_name}</span>
            </>
          )
        },
      },
      {
        title: i18n[this.props.language].t.ticketOrder,
        dataIndex: 'ticket_order',
        key: 'ticket_order',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <>
              {record.ticket_order ? (
                <span>{`${record.ticket_order ||
                  ''}/${record.ticket_order_total || ''}`}</span>
              ) : null}
            </>
          )
        },
      },
    ]
  }

  componentDidMount() {
    const { vehicle_id, active_date } = this.props.match.params

    if (vehicle_id && active_date) {
      this.handleShowChartDetail(vehicle_id, active_date)
    } else {
      this.redirectBack()
    }
  }

  componentWillUnmount() {
    this.onRemoveLists()
  }
  onRemoveLists = () => {
    this.props.removeLists()
  }

  redirectBack = () => {
    this.props.history.push('/monitor-gps')
  }

  //*เมื่อมีการเปลี่ยนแปลง state
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.monitorGPS.check_chart_log !==
      nextProps.monitorGPS.check_chart_log
    ) {
      let temp = nextProps.monitorGPS.chart_log
      if (Object.keys(temp).length > 0) {
        const { active_date } = this.props.match.params
        let chart_data = temp.data || []
        let chart_ticks = temp.ticks || []
        let vehicle_data = temp.vehicle_data || []
        let chart_title = ''
        let ticket_data = temp.ticket_data || []
        if (vehicle_data.length > 0) {
          let vehicle_code = vehicle_data[0].code || '-'
          let plate_no = vehicle_data[0].plate_no || '-'

          chart_title = `${i18n[nextProps.language].d.detail} :
            (${vehicle_code}) ${plate_no} ${
            i18n[nextProps.language].d.date
          } ${moment(active_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}`
        }

        this.setState(
          { chart_data, chart_ticks, chart_title, ticket_data },
          () => {}
        )
      }
    }

    return true
  }

  // *แสดงรายละเอียดกราฟ
  handleShowChartDetail = (vehicle_id, active_date) => {
    this.props.monitorGPSChartLog({
      vehicle_id: vehicle_id,
      start_date: active_date,
      end_date: active_date,
      accessToken: this.props.auth.accessToken,
    })
  }

  render() {
    const { check_chart_log } = this.props.monitorGPS
    const {
      chart_title,
      chart_data,
      chart_color,
      chart_ticks,
      ticket_data,
    } = this.state
    return (
      <AuthorizeComponent {...this.props} matching_name='monitorGPS'>
        <GeneralStyledContent>
          <div className='monitor-gps'>
            {/* header */}
            <PageHeader
              style={{ paddingLeft: '0px' }}
              title={
                <div className='header__text'>
                  {i18n[this.props.language].m.monitorGPS}
                </div>
              }
            />
            {/* แสดงกราฟ */}
            <Row style={{ marginTop: '20px' }} type='flex' align='middle'>
              <Col span={24}>
                <Spin
                  spinning={check_chart_log}
                  tip={`${i18n[this.props.language].l.loading}...`}
                >
                  <TimeChart
                    title={chart_title || i18n[this.props.language].d.detail}
                    chart_data={chart_data}
                    chart_color={chart_color}
                    chart_ticks={chart_ticks}
                  />
                </Spin>
              </Col>
            </Row>

            {/* แสดงตาราง */}
            <Row style={{ marginTop: '20px' }} type='flex' align='middle'>
              <Col span={24}>
                <Table
                  size='small'
                  columns={this.columns}
                  dataSource={ticket_data || []}
                  scroll={{ x: 1000, y: 500 }}
                  bordered
                  className='table-row'
                  pagination={false}
                ></Table>
              </Col>
            </Row>
          </div>
        </GeneralStyledContent>
      </AuthorizeComponent>
    )
  }
}

export default connect(
  ({ monitorGPS, ui: { language }, auth, auth: { profile } }) => ({
    monitorGPS,
    language,
    auth,
    profile,
  }),
  {
    monitorGPSChartLog: monitorGPSChartLog.request,
    removeLists,
  }
)(ViewDetail)
