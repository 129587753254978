import React from "react";
import { icons } from "./Constant";
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: 8,
          padding: "20px",
          opacity: 0.8,
        }}
      >
        <p className="label">
          <strong>วัน:</strong> {label}
        </p>
        <p>
          <strong>{icons["vehicleCloseToTheFront"][["label"]]}:</strong>{" "}
          {payload[0].payload.vehicleCloseToTheFront} ครั้ง
        </p>
        <p>
          <strong>
            {icons["straddlingLanesSuddenlyLeavingLanes"][["label"]]}:
          </strong>{" "}
          {payload[0].payload.straddlingLanesSuddenlyLeavingLanes} ครั้ง
        </p>
        <p>
          <strong>{icons["nearPedestrians"][["label"]]}:</strong>{" "}
          {payload[0].payload.nearPedestrians} ครั้ง
        </p>
        <p>
          <strong>{icons["sleepyYawning"][["label"]]}:</strong>{" "}
          {payload[0].payload.sleepyYawning} ครั้ง
        </p>
        <p>
          <strong>{icons["doNotLookAtTheRoad"][["label"]]}:</strong>{" "}
          {payload[0].payload.doNotLookAtTheRoad} ครั้ง
        </p>
        <p>
          <strong>{icons["smoke"][["label"]]}:</strong>{" "}
          {payload[0].payload.smoke} ครั้ง
        </p>
        <p>
          <strong>{icons["notFoundDriver"][["label"]]}:</strong>{" "}
          {payload[0].payload.notFoundDriver} ครั้ง
        </p>
        <p>
          <strong>{icons["cameraBlocked"][["label"]]}:</strong>{" "}
          {payload[0].payload.cameraBlocked} ครั้ง
        </p>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
