import React, { PureComponent, Fragment } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import {
  Button,
  Form,
  DatePicker,
  AutoComplete,
  Select,
  Badge,
  Modal,
} from "antd";
import LangContext from "modules/shared/context/langContext";
import { genDeviceProcessDetail } from "modules/monitoring/honeytoastdownloadfile/components/deviceProcessFunc";

const FormItem = Form.Item;
const { Option, OptGroup } = AutoComplete;
export default class Filter extends PureComponent {
  state = {
    platenolists: [],
    vehiclelists: [],
  };

  changeVehiclePlateno = (value, option, setFieldValue) => {
    setFieldValue("plate_no", option.props.data_plate);
    setFieldValue("imei", option.props.label);
    let camera = this.getDefaultCameraValue(option.props.label);
    setFieldValue("camera_position", camera);
  };

  selectVehiclePlateno = (value, option, setFieldValue) => {
    setFieldValue("plate_no", option.props.data_plate);
    setFieldValue("imei", option.props.label);
    let camera = this.getDefaultCameraValue(option.props.label);
    setFieldValue("camera_position", camera);
  };

  getDefaultCameraValue = (imei) => {
    let value = "front";
    let rec = this.state.vehiclelists.find((x) => x.imei === imei);
    if (typeof rec !== "undefined") {
      if (rec.camera_channel.length > 0) {
        value = "1";
      }
    }
    return value;
  };

  setVehicleListState = () => {
    let rec = this.props.vehicleLists.filter((x) => x.title !== "fileopt");
    let all = [];
    rec.map((x) => {
      x.children.map((c) => all.push(c));
    });

    this.setState({ vehiclelists: all });
  };

  generateCameraOption = (imei) => {
    if (imei === "") {
      return this.defaultCameraOption();
    }


    let rec = this.state.vehiclelists.find((x) => x.imei === imei);
    if (typeof rec === "undefined") {
      return this.defaultCameraOption();
    } else if (rec.camera_channel.length > 0) {
      return rec.camera_channel.map((x) => {
        return (
          <Option key={x.index.toString()} value={x.index.toString()}>
            {x.name}
          </Option>
        );
      });
    }

    return this.defaultCameraOption();
  };

  defaultCameraOption = () => {
    let list = [
      { value: "front", label_index: "f", label: "front" },
      { value: "back", label_index: "c", label: "camera_back" },
      { value: "extra", label_index: "c", label: "camera_extra" },
    ];

    return list.map((x) => {
      return (
        <Option value={x.value}>
          <LangContext.Consumer>
            {(i18n) => i18n[x.label_index][x.label]}
          </LangContext.Consumer>
        </Option>
      );
    });
  };

  handleOpenDeviceProcessModal = () => {
    let html = genDeviceProcessDetail(this.props.deviceProcess.userList);

    Modal.warning({
      title: "อุปกรณ์นี้ กำลังมีการเชื่อมต่อการใช้งานวีดีโอดังนี้",
      content: <div dangerouslySetInnerHTML={{ __html: html }}></div>,
      okText: "ปิด",
    });
  };

  getFilter = () => {
    let url = new URL(window.location.href);
    let imei = url.searchParams.get("imei");
    if (imei === null) {
      return {
        plate_no: "",
        date: moment(),
        camera_position: "front",
      };
    }

    return {
      plate_no: url.searchParams.get("plate_no"),
      date: moment(url.searchParams.get("date")),
      camera_position: "front",
      imei: imei,
    };
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.vehicleLists.length !== prevProps.vehicleLists.length) {
      this.setVehicleListState();
    }
  }

  componentDidMount() {
    this.setVehicleListState();
  }

  render() {
    let filter = this.getFilter();
    const { onSubmit, vehicleLists } = this.props;
    const platenolists = vehicleLists
      .filter((x) => x.title !== "fileopt")
      .map((group) => {
        return (
          <OptGroup
            key={group.title}
            label={
              <span style={{ color: "blue" }}>
                <LangContext.Consumer>
                  {(i18n) => i18n.o[group.title]}
                </LangContext.Consumer>
              </span>
            }
          >
            {group.children.map((opt) => (
              <Option
                key={opt.id}
                label={opt.imei}
                data_plate={opt.plate_no}
                data_otp={opt.has_file_opt.toString()}
                data_type={opt.device_types_id}
                status={group.title === "offline" ? true : false}
                style={{
                  cursor: group.title === "offline" ? "not-allowed" : "pointer",
                }}
              // disabled={group.title === "offline" ? true : false}
              >
                {group.title === "online" ? (
                  <Badge color="#00BF06" text={opt.plate_no} />
                ) : (
                  <Badge color="#c91c1c" text={opt.plate_no} />
                )}
              </Option>
            ))}
          </OptGroup>
        );
      });

    return (
      <Formik
        initialValues={filter}
        validate={(values) => {
          // let errors = {};
          // return errors;
        }}
        validationSchema={yup.object().shape({
          plate_no: yup.string().required("กรุณาเลือกทะเบียนพาหนะ"),
          date: yup
            .string()
            .required("กรุณาเลือกวันที่")
            .nullable(),
        })}
        onSubmit={(values) => {
          const loading = true;
          onSubmit(values, loading);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          handleFocus,
          setFieldValue,
          resetForm,
        }) => {
          return (
            <Fragment>
              <Form onSubmit={handleSubmit} layout={"inline"}>
                <FormItem>
                  <Button
                    className="blinking"
                    type="danger"
                    disabled={this.props.deviceProcess === null}
                    icon="eye"
                    size="small"
                    onClick={this.handleOpenDeviceProcessModal}
                  />
                </FormItem>

                <FormItem
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.p.plateNoVehicleCode}
                    </LangContext.Consumer>
                  }
                  validateStatus={
                    touched.plate_no && errors.plate_no && "error"
                  }
                  help={touched.plate_no && errors.plate_no}
                >
                  <Select
                    value={values.plate_no}
                    style={{ width: "300px" }}
                    onChange={(value, option) => {
                      if (option.props.status) {
                        alert("เลือกรถที่ออฟไลน์ไม่ได้");
                        setFieldValue("plate_no", "");
                        return;
                      }
                      this.changeVehiclePlateno(value, option, setFieldValue);
                    }}
                    optionLabelProp="data_plate"
                    filterOption={true}
                    optionFilterProp="data_plate"
                    showSearch
                    className="filter-video-playback"
                    size="large"
                    onFocus={this.props.getVehicleList}
                    loading={platenolists.length === 0 ? true : false}
                  >
                    {platenolists}
                  </Select>
                </FormItem>

                <Form.Item
                  required={true}
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.date}
                    </LangContext.Consumer>
                  }
                  validateStatus={touched.date && errors.date && "error"}
                  help={touched.date && errors.date}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => setFieldValue("date", value)}
                    value={values.date}
                    style={{ width: "100%", marginBottom: 10 }}
                    className="filter-video-playback"
                    size="large"
                  />
                </Form.Item>

                <FormItem
                  label={
                    <LangContext.Consumer>
                      {(i18n) => i18n.c.camera_position}
                    </LangContext.Consumer>
                  }
                  validateStatus={
                    touched.camera_position && errors.camera_position && "error"
                  }
                  help={touched.camera_position && errors.camera_position}
                >
                  <Select
                    //showSearch
                    style={{ width: 200 }}
                    onChange={(value) =>
                      setFieldValue("camera_position", value)
                    }
                    value={values.camera_position}
                    size="large"
                  >
                    {this.generateCameraOption(values.imei)}
                  </Select>
                </FormItem>

                <FormItem>
                  <Button
                    htmlType="submit"
                    type="primary"
                    style={{ height: 40 }}
                  >
                    <LangContext.Consumer>
                      {(i18n) => i18n.a.apply}
                    </LangContext.Consumer>
                  </Button>
                </FormItem>
              </Form>
            </Fragment>
          );
        }}
      />
    );
  }
}
