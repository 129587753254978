import React, {
  useState,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import { Row, Col, Spin, Table, Tooltip, Icon } from "antd";
import { icons } from "./Constant";
import ModalInfo from "./ModalInfo";
import ModalVideo from "./ModalVideo";
import { loadReport } from "../fetchAPI/apiClient";
import ModalPhoneNumber from "./ModalPhoneNumber";

const Reports = forwardRef(({ auth, filterObj }, ref) => {
  const [loading, setLoading] = useState(false);
  const [visibleVideo, setVisibleVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [visibleInfo, setVisibleInfo] = useState(false);
  const [vehicleId, setVehicleId] = useState(null);
  const [data, setData] = useState([]);
  const [visiblePhoneNumber, setVisiblePhoneNumber] = useState(false);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    filterColumn();
  }, [filterObj]);

  const filterColumn = () => {
    const columnConfig = [
      {
        types: ["600,601"],
        key: "vehicleCloseToTheFront",
        iconKey: "vehicleCloseToTheFrontReport",
      },
      {
        types: ["602,603,608,609"],
        key: "straddlingLanesSuddenlyLeavingLanes",
        iconKey: "straddlingLanesSuddenlyLeavingLanesReport",
      },
      {
        types: ["606,607"],
        key: "nearPedestrians",
        iconKey: "nearPedestriansReport",
      },
      {
        types: ["618,619"],
        key: "sleepyYawning",
        iconKey: "sleepyYawningReport",
      },
      { types: ["620,621"], key: "call", iconKey: "callReport" },
      {
        types: ["624,625"],
        key: "doNotLookAtTheRoad",
        iconKey: "doNotLookAtTheRoad",
      },
      { types: ["622,623"], key: "smoke", iconKey: "smokeReport" },
      {
        types: ["626,627"],
        key: "notFoundDriver",
        iconKey: "notFoundDriverReport",
      },
      {
        types: ["704,705"],
        key: "cameraBlocked",
        iconKey: "cameraBlockedReport",
      },
    ];

    const newColumns = [...tableColumns];

    columnConfig.forEach(({ types, key, iconKey }) => {
      if (types.some((type) => filterObj.type.includes(type))) {
        newColumns.push({
          title: (
            <Tooltip title={icons[iconKey].label}>
              {icons[iconKey].icon({ fill: "black" })}
            </Tooltip>
          ),
          align: "center",
          dataIndex: key,
          key: key,
          render: (text) => <span>{text}</span>,
        });
      }
    });

    // Add the fixed "Action" column at the end
    newColumns.push({
      title: "Action",
      key: "action",
      align: "center",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <Row gutter={[6, 6]}>
          <Col span={24} align="center">
            <Tooltip
              placement="top"
              title={icons["videoCallReport"].label}
              arrowPointAtCenter
            >
              <Icon
                type="phone"
                style={{
                  fontSize: 16,
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handlePhoneNumber(record.hardwareId);
                }}
              />
            </Tooltip>
          </Col>
          {/* Additional action icons can go here if needed */}
        </Row>
      ),
    });

    setColumns(newColumns);
  };

  const tableColumns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      fixed: "left",
      width: 40,
      align: "center",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "พาหนะ",
      dataIndex: "plateNo",
      key: "plateNo",
      fixed: "left",
      width: 100,
    },
    {
      title: "รายชื่อคนขับ",
      dataIndex: "driverName",
      key: "driverName",
    },
    {
      title: "แจ้งเตือนล่าสุด",
      dataIndex: "latestNoti",
      key: "latestNoti",
    },
    {
      title: "จำนวนครั้งทั้งหมด",
      dataIndex: "totalNumberOfTime",
      key: "totalNumberOfTime",
    },
  ];

  const getReport = (params, loading) => {
    loadReport(params, (status, res) => {
      setData(res.data.data);
      loading();
    });
  };

  const handleVisibleVideo = (url) => {
    setVisibleVideo(true);
    setVideoUrl(url);
  };

  const onCloseModalVideo = () => {
    setVisibleVideo(false);
  };

  const handleInfo = (id) => {
    setVisibleInfo(true);
    setVehicleId(id);
  };

  const onCloseInfo = () => {
    setVisibleInfo(false);
    setVehicleId(null);
  };

  const handlePhoneNumber = (id) => {
    setVisiblePhoneNumber(true);
    setVehicleId(id);
  };

  const onClosePhoneNumber = () => {
    setVisiblePhoneNumber(false);
    setVehicleId(null);
  };

  useImperativeHandle(ref, () => ({
    getReport,
    loading,
  }));

  return (
    <Spin spinning={loading} ref={ref}>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            size="large"
            bordered
            pagination={{
              pageSizeOptions: ["10", "20", "50"],
              showSizeChanger: true,
              defaultPageSize: 10,
            }}
          />
        </Col>
      </Row>
      <ModalInfo
        visibleInfo={visibleInfo}
        vehicleId={vehicleId}
        onCloseInfo={onCloseInfo}
        handleVisibleVideo={handleVisibleVideo}
      />
      <ModalVideo
        videoUrl={videoUrl}
        visibleVideo={visibleVideo}
        onCloseModalVideo={onCloseModalVideo}
      />
      <ModalPhoneNumber
        visiblePhoneNumber={visiblePhoneNumber}
        imei={vehicleId}
        onClosePhoneNumber={onClosePhoneNumber}
      />
    </Spin>
  );
});

export default Reports;
