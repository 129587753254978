import React from "react";
import { Row, Col, Table } from "antd";

const VehicleHistory = ({ vehicleDetail }) => {
  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "แจ้งเตือน",
      dataIndex: "noti",
      key: "noti",
    },
    {
      title: "สถานะผู้ขับ",
      dataIndex: "driver_status",
      key: "driver_status",
    },
    {
      title: "ระยะเวลาจอดพัก",
      dataIndex: "parking_duration",
      key: "parking_duration",
    },
    {
      title: "สถานะการพัก",
      dataIndex: "resting_type",
      key: "resting_type",
    },
    {
      title: "จอดพักไปแล้ว",
      dataIndex: "parked_rested",
      key: "parked_rested",
      render: (text, record, index) => {
        return (
          <div>
            {text.hour} ชั่วโมง {text.min} นาที
          </div>
        );
      },
    },
  ];
  return (
    <Row>
      <Col span={24}>
        <Table
          dataSource={vehicleDetail.caution_data_table}
          columns={columns}
          style={{
            boxShadow: "0px 2px 11px 0px rgba(0, 0, 0, 0.15)",
          }}
          pagination={false}
        />
      </Col>
    </Row>
  );
};

export default VehicleHistory;
