import React, { useState, useEffect } from "react";
import { Row, Col, Icon, Badge, Modal, Button, Spin } from "antd";
import VehicleDetail from "./VehicleDetail";
import VehicleHistory from "./VehicleHistory";
import { DataDetail } from "./Constant";

const ModalInfo = ({
  visibleInfo,
  vehicleId,
  onCloseInfo,
  handleVisibleVideo,
  handleDriverNoti,
}) => {
  const [tabInfo, setTabinfo] = useState("detail");
  const [vehicleDetail, setVehicleDetail] = useState({
    plate_no: "",
    driver_name: "",
    driver_license_number: "",
    vehicle_code: "",
    vehicle_model: "",
    engine_status: "",
    distance_time: {
      distance: "",
      time: "",
    },
    phone: "",
    caution: "",
    parking: "",
    restingStatus: "",
    address: "",
    lat: "",
    lng: "",
    vehicle_id: "",
    data_table: [],
    data_graph: [],
    reference_noti: [],
    caution_data_table: [],
  });
  useEffect(() => {
    setVehicleDetail(DataDetail);
  }, [visibleInfo]);

  const handleTabInfo = (val) => {
    setTabinfo(val);
  };
  const selectedTab = () => {
    switch (tabInfo) {
      case "detail":
        return (
          <VehicleDetail
            vehicleDetail={vehicleDetail}
            handleVisibleVideo={handleVisibleVideo}
            handleDriverNoti={handleDriverNoti}
          />
        );

      case "history":
        return <VehicleHistory vehicleDetail={vehicleDetail} />;

      default:
        break;
    }
  };
  return (
    <Spin spinning={false}>
      <Modal
        title={
          <Row>
            <Col span={3} align="left">
              <Button
                type="link"
                onClick={() => {
                  handleTabInfo("detail");
                }}
                style={{ padding: 0 }}
              >
                <div
                  style={{
                    color: tabInfo === "detail" ? "black" : "#CBCBCB",
                    cursor: "pointer",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  ข้อมูล
                </div>
              </Button>
            </Col>
            <Col span={21} align="left">
              <Button
                type="link"
                onClick={() => {
                  handleTabInfo("history");
                }}
                style={{ padding: 0 }}
              >
                <div
                  style={{
                    color: tabInfo === "history" ? "black" : "#CBCBCB",
                    cursor: "pointer",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  ประวัติการตักเตือน
                </div>
              </Button>
            </Col>
          </Row>
        }
        width={700}
        style={{ top: 10 }}
        onCancel={() => {
          onCloseInfo();
        }}
        footer={null}
        visible={visibleInfo}
      >
        {selectedTab()}
      </Modal>
    </Spin>
  );
};

export default ModalInfo;
