import React from "react";
import { Row, Col, Icon, Badge, Modal, Button, Table } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import CustomTooltip from "./CustomTooltip";

const VehicleDetail = ({
  loading,
  vehicleDetail,
  handleVisibleVideo,
  handleDriverNoti,
}) => {
  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "ประเภท",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "ประเภทการแจ้งเตือน",
      dataIndex: "noti_type",
      key: "noti_type",
    },
    {
      title: "แจ้งเตือนล่าสุด",
      dataIndex: "latest_noti",
      key: "latest_noti",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (text, record, index) => {
        return (
          <div
            onClick={() => {
              handleVisibleVideo(record.url);
            }}
          >
            <Icon
              type="play-circle"
              style={{ fontSize: 16, cursor: "pointer", color: "black" }}
            />
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div style={{ height: 800, overflowY: "auto" }}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <label style={{ fontSize: 18, fontWeight: "bold" }}>
              {vehicleDetail.plate_no}
            </label>
          </Col>
          <Col span={12}>
            <span>
              <label>
                <LangContext.Consumer>
                  {(i18n) => i18n.d.driverName}
                </LangContext.Consumer>
                {" : "}
              </label>
              <label>{vehicleDetail.driver_name}</label>
            </span>
          </Col>
          <Col span={12}>
            <span>
              <label>
                <LangContext.Consumer>
                  {(i18n) => i18n.c.cardId}
                </LangContext.Consumer>
              </label>
              <label>{vehicleDetail.driver_license_number}</label>
            </span>
          </Col>
          <Col span={12}>
            <span>
              <label>
                <LangContext.Consumer>
                  {(i18n) => i18n.p.plateNo}
                </LangContext.Consumer>
                {" : "}
              </label>
              <label>{vehicleDetail.plate_no}</label>
            </span>
          </Col>
          <Col span={12}>
            <span>
              <label>
                <LangContext.Consumer>
                  {(i18n) => i18n.v.vehicleCode}
                </LangContext.Consumer>
                {" : "}
              </label>
              <label>{vehicleDetail.vehicle_code}</label>
            </span>
          </Col>
          <Col span={12}>
            <span>
              <label>
                <LangContext.Consumer>
                  {(i18n) => i18n.v.vehicleModel}
                </LangContext.Consumer>
                {" : "}
              </label>
              <label>{vehicleDetail.vehicle_model}</label>
            </span>
          </Col>
          <Col span={12}>
            <span>
              <label>
                <LangContext.Consumer>
                  {(i18n) => i18n.e.engineStatus}
                </LangContext.Consumer>
                {" : "}
              </label>
              <label>{vehicleDetail.engine_status}</label>
            </span>
          </Col>
          <Col span={12}>
            <span>
              <label>
                <LangContext.Consumer>
                  {(i18n) => i18n.d.distance}
                </LangContext.Consumer>
                &
                <LangContext.Consumer>
                  {(i18n) => i18n.t.time}
                </LangContext.Consumer>
                {" : "}
              </label>
              <label>
                {vehicleDetail.distance_time.distance} กม.{" "}
                {`(${vehicleDetail.distance_time.time} นาที)`}
              </label>
            </span>
          </Col>
          <Col span={12}>
            <span>
              <label>
                <LangContext.Consumer>
                  {(i18n) => i18n.p.phoneNumber}
                </LangContext.Consumer>
                {" : "}
              </label>
              <label>{vehicleDetail.phone}</label>
            </span>
          </Col>
          <Col span={12}>
            <span>
              <label>
                <LangContext.Consumer>
                  {(i18n) => i18n.c.caution}
                </LangContext.Consumer>
                {" : "}
              </label>
              <label>{vehicleDetail.caution} ครั้ง</label>
            </span>
          </Col>
          <Col span={12}>
            <span>
              <label>
                <LangContext.Consumer>
                  {(i18n) => i18n.p.parking}
                </LangContext.Consumer>
                {" : "}
              </label>
              <label>{vehicleDetail.parking} นาที</label>
            </span>
          </Col>
          <Col span={12}>
            <span>
              <label>
                <LangContext.Consumer>
                  {(i18n) => i18n.r.restingStatus}
                </LangContext.Consumer>
                {" : "}
              </label>
              <label>{vehicleDetail.restingStatus}</label>
            </span>
          </Col>
          <Col span={24}>
            <span>
              <label>
                <LangContext.Consumer>
                  {(i18n) => i18n.a.address}
                </LangContext.Consumer>
                {" : "}
              </label>
              <a
                href="/tracking"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                {vehicleDetail.address}
              </a>
            </span>
          </Col>
          <Col span={24}>
            <span>
              <label style={{ fontSize: 16, fontWeight: "bold" }}>
                การแจ้งเตือนการละเมิด
              </label>
            </span>
          </Col>
          <Col span={24}>
            <Table
              dataSource={vehicleDetail.data_table}
              columns={columns}
              style={{
                boxShadow: "0px 2px 11px 0px rgba(0, 0, 0, 0.15)",
              }}
              pagination={false}
            />
          </Col>
          <Col span={24}>
            <span>
              <label style={{ fontSize: 16, fontWeight: "bold" }}>
                กราฟแจ้งเตือนการละเมิด
              </label>
            </span>
          </Col>
          <Col span={24}>
            <div style={{ height: 250, paddingTop: 10 }}>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={500}
                  height={400}
                  data={vehicleDetail.data_graph}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" allowDataOverflow />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Area
                    type="monotone"
                    dataKey="vehicleCloseToTheFront"
                    stackId="1"
                    stroke="#8884d8"
                    fill="#8884d8"
                  />
                  <Area
                    type="monotone"
                    dataKey="straddlingLanesSuddenlyLeavingLanes"
                    stackId="1"
                    stroke="#82ca9d"
                    fill="#82ca9d"
                  />
                  <Area
                    type="monotone"
                    dataKey="nearPedestrians"
                    stackId="1"
                    stroke="#ffc658"
                    fill="#ffc658"
                  />
                  <Area
                    type="monotone"
                    dataKey="sleepyYawning"
                    stackId="1"
                    stroke="#ff8042"
                    fill="#ff8042"
                  />
                  <Area
                    type="monotone"
                    dataKey="doNotLookAtTheRoad"
                    stackId="1"
                    stroke="#8dd1e1"
                    fill="#8dd1e1"
                  />
                  <Area
                    type="monotone"
                    dataKey="smoke"
                    stackId="1"
                    stroke="#d0ed57"
                    fill="#d0ed57"
                  />
                  <Area
                    type="monotone"
                    dataKey="notFoundDriver"
                    stackId="1"
                    stroke="#a4de6c"
                    fill="#a4de6c"
                  />
                  <Area
                    type="monotone"
                    dataKey="cameraBlocked"
                    stackId="1"
                    stroke="#d8867d"
                    fill="#d8867d"
                  />
                  {vehicleDetail.reference_noti.map((item) => (
                    <ReferenceLine x={item} stroke="black" strokeWidth={2} />
                  ))}
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={24} align="center">
          <Button
            size="large"
            style={{
              borderRadius: 8,
              backgroundColor: "#3F3E3E",
              color: "white",
            }}
            onClick={() => {
              handleDriverNoti();
            }}
          >
            แจ้งเตือนไปยังผู้ขับ
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default VehicleDetail;
