import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadVehicleGroup(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      vehicle_visibility,
      vehicle_group_visibility,
      accessToken,
      searchName,
      isIncident,
      companyID,
      permission_add,
      code,
    },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterDataVehicleGroup`,
      {
        page,
        pageSize,
        vehicle_visibility,
        vehicle_group_visibility,
        orderBy,
        orderType,
        searchName,
        isIncident,
        companyID,
        permission_add,
        code,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadVehicleGroup.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadVehicleGroup.failure());
  }
}

function* createVehicleGroup(action) {
  const {
    data: {
      companyID,
      selected,
      vehiclegroupname,
      accessToken,
      IsIncident,
      groupcode,
      partners,
    },
    cb,
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/createVehicleGroup`,
      {
        companyID,
        selected,
        vehiclegroupname,
        IsIncident,
        groupcode,
        partners,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    cb(res.data);
    yield put(actions.createVehicleGroup.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createVehicleGroup.failure());
  }
}

function* loadvehicleGroupDetail(action) {
  const {
    data: { id, page, pageSize, orderBy, orderType, searchName, accessToken },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehiclegroupname/${id}`,
      {
        id,
        page,
        pageSize,
        orderBy,
        orderType,
        searchName,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadvehicleGroupDetail.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadvehicleGroupDetail.failure());
  }
}

function* upDateVehicleGroup(action) {
  const {
    data: {
      vehicle_groups_id,
      companyId,
      groupname,
      groupcode,
      selected,
      accessToken,
      IsIncident,
      unselected,
      partners,
    },
    loading,
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/editVehicleGroup`,
      {
        vehicle_groups_id,
        companyId,
        groupname,
        selected,
        accessToken,
        IsIncident,
        groupcode,
        unselected,
        partners,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.upDateVehicleGroup.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.upDateVehicleGroup.failure());
  }
}

export default function* watchDataVehicleGroup() {
  yield all([
    takeEvery(actions.LOAD_VEHICLEGROUP.REQUEST, loadVehicleGroup),
    takeEvery(actions.CREATE_VEHICLEGROUP.REQUEST, createVehicleGroup),
    takeEvery(actions.LOAD_VEHICLEGROUP_DETAIL.REQUEST, loadvehicleGroupDetail),
    takeEvery(actions.UPDATE_VEHICLEGROUP.REQUEST, upDateVehicleGroup),
  ]);
}

export {
  loadVehicleGroup,
  createVehicleGroup,
  loadvehicleGroupDetail,
  upDateVehicleGroup,
};
