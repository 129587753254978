import axios from "axios";
import { Modal } from "antd";

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("access-token")}`,
  },
});

axiosClient.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    let res = error.response;
    if (res.status === 401) {
      window.location.href = "./";
    }
    // console.error(`Looks like there was a problem. Status Code: ` + res.status);
    return Promise.reject(error);
  }
);

export async function fetchData(params, callback) {
  try {
    const res = await axiosClient.post("/videodownloadqueue/all", params);
    callback(200, res.data.data);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function createQueue(params, callback) {
  try {
    const res = await axiosClient.post("/videodownloadqueue/create", params);
    res.data.params = {
      queue_status: params.queue_status,
      hwid: params.hwid,
      filename: params.filename,
      camera_source: params.camera_source,
    };
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function createQueues(params, callback) {
  try {
    const res = await axiosClient.post(
      "/videodownloadqueue/create-multiple",
      params
    );
    res.data.params = {
      queue_status: params.queue_status,
      hwid: params.hwid,
      filename: params.filename,
    };
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function cancelQueue(params, callback) {
  try {
    const res = await axiosClient.patch(
      "/videodownloadqueue/cancel/" + params.id,
      params
    );
    res.data.params = {
      queue_status: params.queue_status,
      hwid: params.hwid,
      filename: params.filename,
    };
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function reQueue(params, callback) {
  try {
    const res = await axiosClient.patch(
      "/videodownloadqueue/requeue/" + params.id,
      params
    );
    res.data.params = {
      queue_status: params.queue_status,
      hwid: params.hwid,
      filename: params.filename,
    };
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function deleteQueue(params, callback) {
  try {
    const res = await axiosClient.delete(
      "/videodownloadqueue/delete/" + params.id,
      params
    );
    res.data.params = {
      hwid: params.hwid,
      filename: params.filename,
      queue_status: params.queue_status,
    };
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function fetchVechicles(params, callback) {
  try {
    const res = await axiosClient.post(
      "/videodownloadqueue/getvehicleslist",
      params
    );
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function fetchAllConnection(callback) {
  try {
    const allconnect = await axios.get(
      `https://${process.env.REACT_APP_WEBSOCKET}/getAllConnection?idprocess=web` +
        new Date().getTime()
    );
    callback(allconnect.data.message.split(","));
  } catch (error) {
    handleError(error, callback);
  }
}

export async function fetchDeviceProcess(imei, callback) {
  try {
    const allconnect = await axios.get(
      `https://${process.env.REACT_APP_WEBSOCKET}/getDeviceProcessByImei?imei=${imei}`
    );
    callback(allconnect.data);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function getpubliclink(params, callback) {
  try {
    const res = await axiosClient.post(
      "/videodownloadqueue/getpubliclink",
      params
    );
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

function handleError(error, callback) {
  console.log("handleError", error);
  const { response } = error;
  let msg =
    typeof response.data.msg === "string"
      ? response.data.msg
      : response.data.msg.join(", ");
  Modal.error({
    title: "เกิดข้อผิดพลาด",
    content: msg,
  });
  callback(response.status, []);
}
