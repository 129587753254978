// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { Table, Pagination, Button, Tooltip } from 'antd'
import moment from 'moment'

class TableLog extends Component {
  constructor(props) {
    super(props)

    this.columns = [
      {
        title: '#',
        dataIndex: 'key',
        key: 'key',
        width: 50,
        align: 'center',
        fixed: 'left',
        render: (text, record, index) => {
          return (
            <span>
              {this.props.pagi.pageSize * (this.props.pagi.page - 1) +
                index +
                1}
            </span>
          )
        },
      },
      {
        title: i18n[this.props.language].v.vehicleData,
        dataIndex: 'vehicle_id',
        key: 'vehicles.code',
        align: 'center',
        width: 150,
        render: (text, record, index) => {
          return (
            <div>
              <Tooltip
                title={
                  <span>{`(${record.vehicle_code}) ${record.plate_no}`}</span>
                }
                placement='top'
              >
                <span>{`(${record.vehicle_code})`}</span>
                <br />
                <span>{record.plate_no}</span>
              </Tooltip>

              <br />

              {record.device_code && (
                <Tooltip
                  title={
                    <span>{`${
                      i18n[this.props.language].d.deviceCode
                    } : ${record.device_code || ''}`}</span>
                  }
                  placement='top'
                >
                  <span>{`${record.device_code || ''}`}</span>
                </Tooltip>
              )}
            </div>
          )
        },
      },
      {
        title: i18n[this.props.language].v.vehicleType,
        dataIndex: 'vehicle_type_id',
        key: 'vehicle_type_name',
        align: 'center',
        render: (text, record, index) => {
          return (
            <div>
              <Tooltip
                title={
                  <>
                    <span>
                      {`${
                        i18n[this.props.language].v.vehicleType
                      } : ${record.vehicle_type_name || ''}`}
                    </span>
                    <br />
                    {record.is_tax_renewal === 1 && (
                      <span>
                        {i18n[this.props.language].d.dltDataSubmission}
                      </span>
                    )}
                  </>
                }
                placement='top'
              >
                <span
                  style={{
                    color: record.is_tax_renewal === 1 ? 'green' : 'black',
                  }}
                >
                  {`${record.vehicle_type_name || ''}`}
                </span>
              </Tooltip>
            </div>
          )
        },
      },
      {
        title: i18n[this.props.language].m.maintenanceDevice,
        dataIndex: 'vehicle_id',
        key: 'vehicles.code',
        width: 100,
        align: 'center',
        render: (text, record, index) => {
          return (
            <div>
              <Button
                type='link'
                style={{ padding: '0' }}
                size='small'
                onClick={() => {
                  window.open(
                    `servicerepairdevice/create/${record.vehicle_id}/${record.companies_id}`,
                    '_blank'
                  )
                }}
                disabled={
                  (record.maintenance_data &&
                    record.maintenance_data.maintenances_status_id &&
                    [1, 2, 6, 7].includes(
                      record.maintenance_data.maintenances_status_id
                    )) ||
                  record.companies_id !== this.props.auth.profile.company_id
                }
              >
                <span
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {`${i18n[this.props.language].m.maintenanceDevice}`}
                </span>
              </Button>
            </div>
          )
        },
      },
      {
        title: i18n[this.props.language].l.lastOnline,
        children: [
          {
            title: 'TERMINUS',
            dataIndex: 'terminus_gps_datetime',
            align: 'center',
            render: (text, record, index) => {
              return (
                <div>
                  <Tooltip
                    title={
                      <span>{`${i18n[this.props.language].l.lastOnline} : ${
                        record.terminus_gps_datetime
                          ? moment(
                              record.terminus_gps_datetime,
                              'YYYY-MM-DD HH:mm:ss'
                            ).format('DD/MM/YYYY HH:mm:ss')
                          : '-'
                      }`}</span>
                    }
                    placement='top'
                  >
                    <span>{`${
                      record.terminus_gps_datetime
                        ? moment(
                            record.terminus_gps_datetime,
                            'YYYY-MM-DD HH:mm:ss'
                          ).format('DD/MM/YYYY HH:mm:ss')
                        : '-'
                    }`}</span>
                  </Tooltip>
                </div>
              )
            },
          },
          {
            title: i18n[this.props.language].d.DLT,
            dataIndex: 'dlt_gps_datetime',
            align: 'center',
            render: (text, record, index) => {
              return (
                <div>
                  <Tooltip
                    title={
                      <span>{`${i18n[this.props.language].l.lastSubmission} : ${
                        record.dlt_gps_datetime
                          ? moment(
                              record.dlt_gps_datetime,
                              'YYYY-MM-DD HH:mm:ss'
                            ).format('DD/MM/YYYY HH:mm:ss')
                          : '-'
                      }`}</span>
                    }
                    placement='top'
                  >
                    <span>{`${
                      record.dlt_gps_datetime
                        ? moment(
                            record.dlt_gps_datetime,
                            'YYYY-MM-DD HH:mm:ss'
                          ).format('DD/MM/YYYY HH:mm:ss')
                        : '-'
                    }`}</span>
                  </Tooltip>
                </div>
              )
            },
          },
        ],
      },
      {
        title: i18n[this.props.language].l.lastLicenseSwipe,
        children: [
          {
            title: 'TERMINUS',
            dataIndex: 'terminus_license_scan_id',
            key: 'terminus_license_scan_id',
            align: 'center',
            render: (text, record, index) => {
              return (
                <div>
                  <div>
                    <Tooltip
                      title={
                        <span>{`${
                          i18n[this.props.language].u.updated_driving_license_at
                        } : ${
                          record.terminus_license_scan_datetime
                            ? moment(
                                record.terminus_license_scan_datetime,
                                'YYYY-MM-DD HH:mm:ss'
                              ).format('DD/MM/YYYY HH:mm:ss')
                            : '-'
                        }`}</span>
                      }
                      placement='top'
                    >
                      <span>
                        {record.terminus_license_scan_datetime
                          ? moment(
                              record.terminus_license_scan_datetime,
                              'YYYY-MM-DD HH:mm:ss'
                            ).format('DD/MM/YYYY HH:mm:ss')
                          : '-'}
                      </span>
                    </Tooltip>
                  </div>
                  <div>
                    <Tooltip
                      title={
                        <span>{`${
                          i18n[this.props.language].d.drivingIicenseNumber
                        } : ${record.terminus_license_scan_id || '-'}`}</span>
                      }
                      placement='top'
                    >
                      <span>{record.terminus_license_scan_id || '-'}</span>
                    </Tooltip>
                  </div>
                </div>
              )
            },
          },
          {
            title: i18n[this.props.language].d.DLT,
            dataIndex: 'dlt_driving_license_id',
            key: 'dlt_driving_license_id',
            align: 'center',
            render: (text, record, index) => {
              return (
                <div>
                  <div>
                    <Tooltip
                      title={
                        <span>{`${
                          i18n[this.props.language].d.drivingIicenseNumber
                        } : ${record.dlt_driving_license_id || '-'}`}</span>
                      }
                      placement='top'
                    >
                      <span>{record.dlt_driving_license_id || '-'}</span>
                    </Tooltip>
                  </div>
                </div>
              )
            },
          },
        ],
      },
      {
        title: i18n[this.props.language].l.lastCheckDLT,
        dataIndex: 'dlt_checked_at',
        key: 'dlt_checked_at',
        align: 'center',
        render: (text, record, index) => {
          return (
            <div>
              <Tooltip
                title={
                  <span>{`${i18n[this.props.language].l.lastCheckDLT} : ${
                    record.dlt_checked_at
                      ? moment(
                          record.dlt_checked_at,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format('DD/MM/YYYY HH:mm:ss')
                      : '-'
                  }`}</span>
                }
                placement='top'
              >
                <span>{`${
                  record.dlt_checked_at
                    ? moment(
                        record.dlt_checked_at,
                        'YYYY-MM-DD HH:mm:ss'
                      ).format('DD/MM/YYYY HH:mm:ss')
                    : '-'
                }`}</span>
              </Tooltip>
            </div>
          )
        },
      },
      {
        title: i18n[this.props.language].d.dltCheck,
        dataIndex: 'vehicle_id',
        align: 'center',
        width: 100,
        fixed: 'right',
        render: (text, record, index) => {
          return (
            <div>
              <Tooltip
                title={<span>{`${i18n[this.props.language].d.dltCheck}`}</span>}
                placement='top'
              >
                <Button
                  type='link'
                  style={{ padding: '0' }}
                  size='small'
                  onClick={() => {
                    this.props.handleCheckDLT(record)
                  }}
                  icon='sync'
                  loading={record.loading || false}
                ></Button>
              </Tooltip>
            </div>
          )
        },
      },
    ]
  }

  render() {
    const { data, pagi, total, handleChangePage } = this.props

    return (
      <div className={'tax-renewal-table-log-div'}>
        <div>
          <Table
            size='small'
            rowKey={(record) => record.key}
            columns={this.columns}
            dataSource={data}
            scroll={{ x: 1000, y: 1000 }}
            bordered
            pagination={false}
            className='tax-renewal-table-log'
          ></Table>
        </div>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Pagination
            current={pagi.page}
            page={pagi.page}
            pageSize={pagi.pageSize}
            total={total}
            onChange={(page, pageSize) => handleChangePage(page, pageSize)}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  ({ ui: { language }, auth, auth: { profile } }) => ({
    auth,
    language,
  }),
  {}
)(TableLog)
