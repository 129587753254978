import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getlocalstorage } from './../../../constants/local_storage'

import * as actions from './actions'
import moment from 'moment'

function* loadTaxiFare(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id'
  )
  const USER_ID = getlocalstorage(localStorage.getItem("profile"), "id");
  const VEHICLE_VISIBILITY = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility'
  )

  const {
    data: { eventdate, type_file, list_vehicle_id, vehicle_type_id },
  } = action.payload
  const date_start = moment(eventdate[0]['_d']).format('YYYY-MM-DD')
  const date_end = moment(eventdate[1]['_d']).format('YYYY-MM-DD')
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/taxifare`,
      {
        date_start,
        date_end,
        list_vehicle_id,
        company_id: COMPANY_ID,
        vehicle_type_id,
        user_id:USER_ID,
        type_file,
        vehicle_visibility: VEHICLE_VISIBILITY,
      
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    )
    yield put(actions.loadTaxiFare.success(res.data))
    window.location = res.data.data
  } catch (err) {
    yield put(actions.loadTaxiFare.failure())
  }
}

function* loadVehicle(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id'
  )

  const vehicle_visibility = getlocalstorage(
    localStorage.getItem('profile'),
    'vehicle_visibility'
  )

  const { vehicletypeID } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`,
      {
        companyID: COMPANY_ID,
        vehicletypeID: vehicletypeID,
        allow_vehicles: vehicle_visibility,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    )
    yield put(actions.loadVehicle.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicle.failure())
  }
}

function* loadVehicleType(action) {
  const ACCESSTOKEN = localStorage.getItem('access-token')
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem('profile'),
    'company_id'
  )

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getgroupvehicle`,
      {
        company_id: COMPANY_ID,
      },
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    )
    yield put(actions.loadVehicleType.success(res.data))
  } catch (err) {
    yield put(actions.loadVehicleType.failure())
  }
}

export default function* watchTaxifare() {
  yield all([takeEvery(actions.LOAD_TAXIFARE.REQUEST, loadTaxiFare)])
  yield all([takeEvery(actions.LOAD_VEHICLE_TAXIFARE.REQUEST, loadVehicle)])
  yield all([
    takeEvery(actions.LOAD_VEHICLE_TYPE_TAXIFARE.REQUEST, loadVehicleType),
  ])
}

export { loadTaxiFare, loadVehicle, loadVehicleType }
