import React, { useState, useEffect } from "react";
import { Row, Col, Icon, Badge, Modal, Button, Spin } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { getPhoneNumber } from "../fetchAPI/apiClient";

const ModalPhoneNumber = ({ visiblePhoneNumber, imei, onClosePhoneNumber }) => {
  const [data, setData] = useState({
    phone_number: "",
  });
  useEffect(() => {
    if (visiblePhoneNumber) {
      getPhoneNumber({ imei }, (statusCode, response) => {
        setData(response.data);
      });
    }
  }, [visiblePhoneNumber]);

  return (
    <Spin spinning={false}>
      <Modal
        title={
          <Row>
            <Col span={24} align="center">
              <LangContext.Consumer>
                {(i18n) => i18n.d.driverPhoneNumber}
              </LangContext.Consumer>
            </Col>
          </Row>
        }
        onCancel={() => {
          onClosePhoneNumber();
        }}
        footer={null}
        visible={visiblePhoneNumber}
      >
        <LangContext.Consumer>
          {(i18n) => i18n.p.phoneNumber}
        </LangContext.Consumer>
        : {data.phone_no}
      </Modal>
    </Spin>
  );
};

export default ModalPhoneNumber;
