import React from "react";
import { ReactComponent as VehicleCloseToTheFrontReport } from "../Icon/VehicleCloseToTheFront.svg";
import { ReactComponent as StraddlingLanesSuddenlyLeavingLanesReport } from "../Icon/StraddlingLanesSuddenlyLeavingLanes.svg";
import { ReactComponent as NearPedestriansReport } from "../Icon/NearPedestrians.svg";
import { ReactComponent as SleepyYawningReport } from "../Icon/SleepyYawning.svg";
import { ReactComponent as CallReport } from "../Icon/call.svg";
import { ReactComponent as DoNotLookAtTheRoadReport } from "../Icon/DoNotLookAtTheRoad.svg";
import { ReactComponent as SmokeReport } from "../Icon/Smoke.svg";
import { ReactComponent as NotFoundDriverReport } from "../Icon/NotFoundDriver.svg";
import { ReactComponent as CameraBlockedReport } from "../Icon/CameraBlocked.svg";
import { ReactComponent as TrackingReport } from "../Icon/Tracking.svg";
import { ReactComponent as VideoRealTimeReport } from "../Icon/VideoRealTime.svg";
import { ReactComponent as InfoReport } from "../Icon/Info.svg";
import { ReactComponent as VideoCallReport } from "../Icon/Vector.svg";
import { ReactComponent as YellowStarReport } from "../Icon/yellowStar.svg";
//icon for video
import { ReactComponent as VehicleCloseToTheFront } from "../Icon/VideoIcon/VehicleCloseToTheFront.svg";
import { ReactComponent as StraddlingLanesSuddenlyLeavingLanes } from "../Icon/VideoIcon/StraddlingLanesSuddenlyLeavingLanes.svg";
import { ReactComponent as NearPedestrians } from "../Icon/VideoIcon/NearPedestrians.svg";
import { ReactComponent as SleepyYawning } from "../Icon/VideoIcon/SleepyYawning.svg";
import { ReactComponent as Call } from "../Icon/VideoIcon/call.svg";
import { ReactComponent as DoNotLookAtTheRoad } from "../Icon/VideoIcon/DoNotLookAtTheRoad.svg";
import { ReactComponent as Smoke } from "../Icon/VideoIcon/Smoke.svg";
import { ReactComponent as NotFoundDriver } from "../Icon/VideoIcon/NotFoundDriver.svg";
import { ReactComponent as CameraBlocked } from "../Icon/VideoIcon/CameraBlocked.svg";
import { ReactComponent as Info } from "../Icon/VideoIcon/Info.svg";
import { ReactComponent as YellowStar } from "../Icon/VideoIcon/yellowStar.svg";
const tabs = [
  {
    name: "Video",
    id: 1,
  },
  {
    name: "Bookmarks",
    id: 2,
  },
  {
    name: "Reports",
    id: 3,
  },
];

const searchTree = {
  key: 0,
  name: "เหตุการณ์ทั้งหมด",
  treeNode: [
    {
      key: 1,
      name: "ADAS",
      subTreeNode: [
        {
          key: "600,601",
          name: "vehicleCloseToTheFront",
        },
        {
          key: "602,603,608,609",
          name: "straddlingLanesSuddenlyLeavingLanes",
        },
        {
          key: "606,607",
          name: "nearPedestrians",
        },
      ],
    },
    {
      key: 5,
      name: "DMS",
      subTreeNode: [
        {
          key: "618,619",
          name: "sleepyYawning",
        },
        {
          key: "620,621",
          name: "call",
        },
        {
          key: "624,625",
          name: "doNotLookAtTheRoad",
        },
        {
          key: "622,623",
          name: "smoke",
        },
        {
          key: "626,627",
          name: "notFoundDriver",
        },
        {
          key: "704,705",
          name: "cameraBlocked",
        },
      ],
    },
  ],
};

const icons = {
  vehicleCloseToTheFrontReport: {
    label: "รถกระชั้นชิด คันหน้า",
    icon: ({ fill }) => <VehicleCloseToTheFrontReport fill={fill} />,
  },
  straddlingLanesSuddenlyLeavingLanesReport: {
    label: "ขับคล่อมเลน, ออกนอกเลนกระทันหัน",
    icon: ({ fill }) => (
      <StraddlingLanesSuddenlyLeavingLanesReport fill={fill} />
    ),
  },
  nearPedestriansReport: {
    label: "ใกล้คนเดินถนน",
    icon: ({ fill }) => <NearPedestriansReport fill={fill} />,
  },
  sleepyYawningReport: {
    label: "ง่วง, หาว",
    icon: ({ fill }) => <SleepyYawningReport fill={fill} />,
  },
  callReport: {
    label: "ใช้โทรศัพท์",
    icon: ({ fill }) => <CallReport fill={fill} />,
  },
  doNotLookAtTheRoadReport: {
    label: "ไม่มองทาง",
    icon: ({ fill }) => <DoNotLookAtTheRoadReport fill={fill} />,
  },
  smokeReport: {
    label: "สูบบุหรี่",
    icon: ({ fill }) => <SmokeReport fill={fill} />,
  },
  notFoundDriverReport: {
    label: "ไม่พบคนขับ",
    icon: ({ fill }) => <NotFoundDriverReport fill={fill} />,
  },
  cameraBlockedReport: {
    label: "กล้องถูกบัง",
    icon: ({ fill }) => <CameraBlockedReport fill={fill} />,
  },
  trackingReport: {
    label: "ติดตามพาหนะ",
    icon: ({ fill }) => <TrackingReport stroke={fill} />,
  },
  videoRealTimeReport: {
    label: "วิดีโอเรียลไทม์",
    icon: ({ fill }) => <VideoRealTimeReport fill={fill} />,
  },
  infoReport: {
    label: "รายละเอียด",
    icon: ({ fill }) => <InfoReport fill={fill} />,
  },
  videoCallReport: {
    label: "โทร",
    icon: ({ fill }) => <VideoCallReport fill={fill} />,
  },
  yellowStarReport: {
    label: "ดาวเหลือง",
    icon: ({ fill }) => <YellowStarReport />,
  },
  //video icon
  vehicleCloseToTheFront: {
    label: "รถกระชั้นชิด คันหน้า",
    icon: ({ fill }) => <VehicleCloseToTheFront fill={fill} />,
  },
  straddlingLanesSuddenlyLeavingLanes: {
    label: "ขับคล่อมเลน, ออกนอกเลนกระทันหัน",
    icon: ({ fill }) => <StraddlingLanesSuddenlyLeavingLanes fill={fill} />,
  },
  nearPedestrians: {
    label: "ใกล้คนเดินถนน",
    icon: ({ fill }) => <NearPedestrians fill={fill} />,
  },
  sleepyYawning: {
    label: "ง่วง, หาว",
    icon: ({ fill }) => <SleepyYawning fill={fill} />,
  },
  call: {
    label: "ใช้โทรศัพท์",
    icon: ({ fill }) => <Call fill={fill} />,
  },
  doNotLookAtTheRoad: {
    label: "ไม่มองทาง",
    icon: ({ fill }) => <DoNotLookAtTheRoad fill={fill} />,
  },
  smoke: {
    label: "สูบบุหรี่",
    icon: ({ fill }) => <Smoke fill={fill} />,
  },
  notFoundDriver: {
    label: "ไม่พบคนขับ",
    icon: ({ fill }) => <NotFoundDriver fill={fill} />,
  },
  cameraBlocked: {
    label: "กล้องถูกบัง",
    icon: ({ fill }) => <CameraBlocked fill={fill} />,
  },
  info: {
    label: "รายละเอียด",
    icon: ({ fill }) => <Info fill={fill} />,
  },
  yellowStar: {
    label: "ดาวเหลือง",
    icon: ({ fill }) => <YellowStar />,
  },
};

const data = [
  {
    number: 1, //rownumber pagination
    plantNo: "กก-735",
    driverName: "Lorem Ipsum",
    phoneNumber: "0891112222",
    latestNoti: "30/12/1999 23:59:59",
    continueDrive: "48:23:98",
    speed: 80,
    totalNumberOfTime: 3,
    vehicleCloseToTheFront: 4,
    straddlingLanesSuddenlyLeavingLanes: 1,
    nearPedestrians: 3,
    sleepyYawning: 1,
    call: 1,
    doNotLookAtTheRoad: 0,
    smoke: 0,
    notFoundDriver: 1,
    cameraBlocked: 0,
  },
  {
    number: 2, //rownumber pagination
    plantNo: "กก-755",
    driverName: "Lorem Ipsum",
    phoneNumber: "0894321443",
    latestNoti: "30/12/1999 23:59:59",
    continueDrive: "48:23:98",
    speed: 40,
    totalNumberOfTime: 1,
    vehicleCloseToTheFront: 4,
    straddlingLanesSuddenlyLeavingLanes: 1,
    nearPedestrians: 3,
    sleepyYawning: 1,
    call: 1,
    doNotLookAtTheRoad: 0,
    smoke: 0,
    notFoundDriver: 1,
    cameraBlocked: 0,
  },
  {
    number: 3, //rownumber pagination

    plantNo: "dd-2214",
    driverName: "Lorem Ipsum",
    phoneNumber: "0894432113",
    latestNoti: "30/12/1999 23:59:59",
    continueDrive: "48:23:98",
    speed: 90,
    totalNumberOfTime: 3,
    vehicleCloseToTheFront: 4,
    straddlingLanesSuddenlyLeavingLanes: 1,
    nearPedestrians: 3,
    sleepyYawning: 1,
    call: 1,
    doNotLookAtTheRoad: 0,
    smoke: 0,
    notFoundDriver: 1,
    cameraBlocked: 0,
  },
];

const videoData = {
  bookmark_video_list: [
    {
      id: 5,
      vehicle_info: { plate_no: "MT-02020 (3321312)", code: "123" },
      alert: false,

      auto_play: false,
      event_stat: [
        {
          event_name: "vehicleCloseToTheFront",
          event_type: 1,
          event_status: 1,
        },
        {
          event_name: "straddlingLanesSuddenlyLeavingLanes",
          event_type: 2,
          event_status: 0,
        },
        {
          event_name: "nearPedestrians",
          event_type: 3,
          event_status: 0,
        },
        {
          event_name: "sleepyYawning",
          event_type: 4,
          event_status: 0,
        },
        {
          event_name: "call",
          event_type: 5,
          event_status: 2,
        },
        {
          event_name: "doNotLookAtTheRoad",
          event_type: 6,
          event_status: 0,
        },
        {
          event_name: "smoke",
          event_type: 7,
          event_status: 0,
        },
        {
          event_name: "notFoundDriver",
          event_type: 8,
          event_status: 0,
        },
        {
          event_name: "cameraBlocked",
          event_type: 9,
          event_status: 0,
        },
      ],
      lasted_event: 1,
      link_video:
        "http://61.91.80.201:6611/3/5?DownType=3&DevIDNO=068110603010&FLENGTH=1081018&FOFFSET=0&MTYPE=1&FPATH=C%3A%2FgStorage%2FRECORD_FILE%2F068110603010%2F2024-10-21%2F02_02_6405_4_00068110603010241021204130240500.mp4&jsession=d10e5a2891fd49efbdb3c5abbe0b9aa",
    },
    {
      id: 6,
      vehicle_info: { plate_no: "MT-02020 (3321312)", code: "123" },
      alert: false,

      auto_play: false,
      event_stat: [
        {
          event_name: "vehicleCloseToTheFront",
          event_type: 1,
          event_status: 1,
        },
        {
          event_name: "straddlingLanesSuddenlyLeavingLanes",
          event_type: 2,
          event_status: 0,
        },
        {
          event_name: "nearPedestrians",
          event_type: 3,
          event_status: 0,
        },
        {
          event_name: "sleepyYawning",
          event_type: 4,
          event_status: 0,
        },
        {
          event_name: "call",
          event_type: 5,
          event_status: 2,
        },
        {
          event_name: "doNotLookAtTheRoad",
          event_type: 6,
          event_status: 0,
        },
        {
          event_name: "smoke",
          event_type: 7,
          event_status: 0,
        },
        {
          event_name: "notFoundDriver",
          event_type: 8,
          event_status: 0,
        },
        {
          event_name: "cameraBlocked",
          event_type: 9,
          event_status: 0,
        },
      ],
      lasted_event: 1,
      link_video:
        "http://61.91.80.201:6611/3/5?DownType=3&DevIDNO=068110603010&FLENGTH=1081018&FOFFSET=0&MTYPE=1&FPATH=C%3A%2FgStorage%2FRECORD_FILE%2F068110603010%2F2024-10-21%2F02_02_6405_4_00068110603010241021204130240500.mp4&jsession=d10e5a2891fd49efbdb3c5abbe0b9aa",
    },
    {
      id: 7,
      vehicle_info: { plate_no: "MT-02020 (3321312)", code: "123" },
      alert: false,

      auto_play: false,
      event_stat: [
        {
          event_name: "vehicleCloseToTheFront",
          event_type: 1,
          event_status: 1,
        },
        {
          event_name: "straddlingLanesSuddenlyLeavingLanes",
          event_type: 2,
          event_status: 0,
        },
        {
          event_name: "nearPedestrians",
          event_type: 3,
          event_status: 0,
        },
        {
          event_name: "sleepyYawning",
          event_type: 4,
          event_status: 0,
        },
        {
          event_name: "call",
          event_type: 5,
          event_status: 2,
        },
        {
          event_name: "doNotLookAtTheRoad",
          event_type: 6,
          event_status: 0,
        },
        {
          event_name: "smoke",
          event_type: 7,
          event_status: 0,
        },
        {
          event_name: "notFoundDriver",
          event_type: 8,
          event_status: 0,
        },
        {
          event_name: "cameraBlocked",
          event_type: 9,
          event_status: 0,
        },
      ],
      lasted_event: 1,
      link_video:
        "http://61.91.80.201:6611/3/5?DownType=3&DevIDNO=068110603010&FLENGTH=1081018&FOFFSET=0&MTYPE=1&FPATH=C%3A%2FgStorage%2FRECORD_FILE%2F068110603010%2F2024-10-21%2F02_02_6405_4_00068110603010241021204130240500.mp4&jsession=d10e5a2891fd49efbdb3c5abbe0b9aa",
    },
    {
      id: 8,
      vehicle_info: { plate_no: "MT-02020 (3321312)", code: "123" },
      alert: false,
      auto_play: false,
      event_stat: [
        {
          event_name: "vehicleCloseToTheFront",
          event_type: 1,
          event_status: 1,
        },
        {
          event_name: "straddlingLanesSuddenlyLeavingLanes",
          event_type: 2,
          event_status: 0,
        },
        {
          event_name: "nearPedestrians",
          event_type: 3,
          event_status: 0,
        },
        {
          event_name: "sleepyYawning",
          event_type: 4,
          event_status: 0,
        },
        {
          event_name: "call",
          event_type: 5,
          event_status: 2,
        },
        {
          event_name: "doNotLookAtTheRoad",
          event_type: 6,
          event_status: 0,
        },
        {
          event_name: "smoke",
          event_type: 7,
          event_status: 0,
        },
        {
          event_name: "notFoundDriver",
          event_type: 8,
          event_status: 0,
        },
        {
          event_name: "cameraBlocked",
          event_type: 9,
          event_status: 0,
        },
      ],
      lasted_event: 1,
      link_video:
        "http://61.91.80.201:6611/3/5?DownType=3&DevIDNO=068110603010&FLENGTH=1081018&FOFFSET=0&MTYPE=1&FPATH=C%3A%2FgStorage%2FRECORD_FILE%2F068110603010%2F2024-10-21%2F02_02_6405_4_00068110603010241021204130240500.mp4&jsession=d10e5a2891fd49efbdb3c5abbe0b9aa",
    },
    {
      id: 9,
      vehicle_info: { plate_no: "MT-02020 (3321312)", code: "123" },
      alert: false,
      auto_play: false,
      event_stat: [
        {
          event_name: "vehicleCloseToTheFront",
          event_type: 1,
          event_status: 1,
        },
        {
          event_name: "straddlingLanesSuddenlyLeavingLanes",
          event_type: 2,
          event_status: 0,
        },
        {
          event_name: "nearPedestrians",
          event_type: 3,
          event_status: 0,
        },
        {
          event_name: "sleepyYawning",
          event_type: 4,
          event_status: 0,
        },
        {
          event_name: "call",
          event_type: 5,
          event_status: 2,
        },
        {
          event_name: "doNotLookAtTheRoad",
          event_type: 6,
          event_status: 0,
        },
        {
          event_name: "smoke",
          event_type: 7,
          event_status: 0,
        },
        {
          event_name: "notFoundDriver",
          event_type: 8,
          event_status: 0,
        },
        {
          event_name: "cameraBlocked",
          event_type: 9,
          event_status: 0,
        },
      ],
      lasted_event: 1,
      link_video:
        "http://61.91.80.201:6611/3/5?DownType=3&DevIDNO=068110603010&FLENGTH=1081018&FOFFSET=0&MTYPE=1&FPATH=C%3A%2FgStorage%2FRECORD_FILE%2F068110603010%2F2024-10-21%2F02_02_6405_4_00068110603010241021204130240500.mp4&jsession=d10e5a2891fd49efbdb3c5abbe0b9aa",
    },
    {
      id: 10,
      vehicle_info: { plate_no: "MT-02020 (3321312)", code: "123" },
      alert: false,
      auto_play: false,
      event_stat: [
        {
          event_name: "vehicleCloseToTheFront",
          event_type: 1,
          event_status: 1,
        },
        {
          event_name: "straddlingLanesSuddenlyLeavingLanes",
          event_type: 2,
          event_status: 0,
        },
        {
          event_name: "nearPedestrians",
          event_type: 3,
          event_status: 0,
        },
        {
          event_name: "sleepyYawning",
          event_type: 4,
          event_status: 0,
        },
        {
          event_name: "call",
          event_type: 5,
          event_status: 2,
        },
        {
          event_name: "doNotLookAtTheRoad",
          event_type: 6,
          event_status: 0,
        },
        {
          event_name: "smoke",
          event_type: 7,
          event_status: 0,
        },
        {
          event_name: "notFoundDriver",
          event_type: 8,
          event_status: 0,
        },
        {
          event_name: "cameraBlocked",
          event_type: 9,
          event_status: 0,
        },
      ],
      lasted_event: 1,
      link_video:
        "http://61.91.80.201:6611/3/5?DownType=3&DevIDNO=068110603010&FLENGTH=1081018&FOFFSET=0&MTYPE=1&FPATH=C%3A%2FgStorage%2FRECORD_FILE%2F068110603010%2F2024-10-21%2F02_02_6405_4_00068110603010241021204130240500.mp4&jsession=d10e5a2891fd49efbdb3c5abbe0b9aa",
    },
    {
      id: 11,
      vehicle_info: { plate_no: "MT-02020 (3321312)", code: "123" },
      alert: false,
      auto_play: false,
      event_stat: [
        {
          event_name: "vehicleCloseToTheFront",
          event_type: 1,
          event_status: 1,
        },
        {
          event_name: "straddlingLanesSuddenlyLeavingLanes",
          event_type: 2,
          event_status: 0,
        },
        {
          event_name: "nearPedestrians",
          event_type: 3,
          event_status: 0,
        },
        {
          event_name: "sleepyYawning",
          event_type: 4,
          event_status: 0,
        },
        {
          event_name: "call",
          event_type: 5,
          event_status: 2,
        },
        {
          event_name: "doNotLookAtTheRoad",
          event_type: 6,
          event_status: 0,
        },
        {
          event_name: "smoke",
          event_type: 7,
          event_status: 0,
        },
        {
          event_name: "notFoundDriver",
          event_type: 8,
          event_status: 0,
        },
        {
          event_name: "cameraBlocked",
          event_type: 9,
          event_status: 0,
        },
      ],
      lasted_event: 1,
      link_video:
        "http://61.91.80.201:6611/3/5?DownType=3&DevIDNO=068110603010&FLENGTH=1081018&FOFFSET=0&MTYPE=1&FPATH=C%3A%2FgStorage%2FRECORD_FILE%2F068110603010%2F2024-10-21%2F02_02_6405_4_00068110603010241021204130240500.mp4&jsession=d10e5a2891fd49efbdb3c5abbe0b9aa",
    },
    {
      id: 12,
      vehicle_info: { plate_no: "MT-02020 (3321312)", code: "123" },
      alert: false,
      auto_play: false,
      event_stat: [
        {
          event_name: "vehicleCloseToTheFront",
          event_type: 1,
          event_status: 1,
        },
        {
          event_name: "straddlingLanesSuddenlyLeavingLanes",
          event_type: 2,
          event_status: 0,
        },
        {
          event_name: "nearPedestrians",
          event_type: 3,
          event_status: 0,
        },
        {
          event_name: "sleepyYawning",
          event_type: 4,
          event_status: 0,
        },
        {
          event_name: "call",
          event_type: 5,
          event_status: 2,
        },
        {
          event_name: "doNotLookAtTheRoad",
          event_type: 6,
          event_status: 0,
        },
        {
          event_name: "smoke",
          event_type: 7,
          event_status: 0,
        },
        {
          event_name: "notFoundDriver",
          event_type: 8,
          event_status: 0,
        },
        {
          event_name: "cameraBlocked",
          event_type: 9,
          event_status: 0,
        },
      ],
      lasted_event: 1,
      link_video:
        "http://61.91.80.201:6611/3/5?DownType=3&DevIDNO=068110603010&FLENGTH=1081018&FOFFSET=0&MTYPE=1&FPATH=C%3A%2FgStorage%2FRECORD_FILE%2F068110603010%2F2024-10-21%2F02_02_6405_4_00068110603010241021204130240500.mp4&jsession=d10e5a2891fd49efbdb3c5abbe0b9aa",
    },
    {
      id: 13,
      vehicle_info: { plate_no: "MT-02020 (3321312)", code: "123" },
      alert: false,
      auto_play: false,
      event_stat: [
        {
          event_name: "vehicleCloseToTheFront",
          event_type: 1,
          event_status: 1,
        },
        {
          event_name: "straddlingLanesSuddenlyLeavingLanes",
          event_type: 2,
          event_status: 0,
        },
        {
          event_name: "nearPedestrians",
          event_type: 3,
          event_status: 0,
        },
        {
          event_name: "sleepyYawning",
          event_type: 4,
          event_status: 0,
        },
        {
          event_name: "call",
          event_type: 5,
          event_status: 2,
        },
        {
          event_name: "doNotLookAtTheRoad",
          event_type: 6,
          event_status: 0,
        },
        {
          event_name: "smoke",
          event_type: 7,
          event_status: 0,
        },
        {
          event_name: "notFoundDriver",
          event_type: 8,
          event_status: 0,
        },
        {
          event_name: "cameraBlocked",
          event_type: 9,
          event_status: 0,
        },
      ],
      lasted_event: 1,
      link_video:
        "http://61.91.80.201:6611/3/5?DownType=3&DevIDNO=068110603010&FLENGTH=1081018&FOFFSET=0&MTYPE=1&FPATH=C%3A%2FgStorage%2FRECORD_FILE%2F068110603010%2F2024-10-21%2F02_02_6405_4_00068110603010241021204130240500.mp4&jsession=d10e5a2891fd49efbdb3c5abbe0b9aa",
    },
  ],
  video_list: [
    {
      id: 1,
      vehicle_info: { plate_no: "MT-02020 (3321312)", code: "123" },
      alert: false,
      auto_play: false,
      event_stat: [
        {
          event_name: "vehicleCloseToTheFront",
          event_type: 1,
          event_status: 1,
        },
        {
          event_name: "straddlingLanesSuddenlyLeavingLanes",
          event_type: 2,
          event_status: 0,
        },
        {
          event_name: "nearPedestrians",
          event_type: 3,
          event_status: 0,
        },
        {
          event_name: "sleepyYawning",
          event_type: 4,
          event_status: 0,
        },
        {
          event_name: "call",
          event_type: 5,
          event_status: 2,
        },
        {
          event_name: "doNotLookAtTheRoad",
          event_type: 6,
          event_status: 0,
        },
        {
          event_name: "smoke",
          event_type: 7,
          event_status: 0,
        },
        {
          event_name: "notFoundDriver",
          event_type: 8,
          event_status: 0,
        },
        {
          event_name: "cameraBlocked",
          event_type: 9,
          event_status: 0,
        },
      ],
      lasted_event: 1,
      link_video:
        "http://61.91.80.201:6611/3/5?DownType=3&DevIDNO=068110603010&FLENGTH=1081018&FOFFSET=0&MTYPE=1&FPATH=C%3A%2FgStorage%2FRECORD_FILE%2F068110603010%2F2024-10-21%2F02_02_6405_4_00068110603010241021204130240500.mp4&jsession=d10e5a2891fd49efbdb3c5abbe0b9aa",
    },
    {
      id: 2,
      vehicle_info: { plate_no: "MT-02020 (3321312)", code: "123" },
      alert: false,
      auto_play: false,
      event_stat: [
        {
          event_name: "vehicleCloseToTheFront",
          event_type: 1,
          event_status: 1,
        },
        {
          event_name: "straddlingLanesSuddenlyLeavingLanes",
          event_type: 2,
          event_status: 0,
        },
        {
          event_name: "nearPedestrians",
          event_type: 3,
          event_status: 0,
        },
        {
          event_name: "sleepyYawning",
          event_type: 4,
          event_status: 0,
        },
        {
          event_name: "call",
          event_type: 5,
          event_status: 2,
        },
        {
          event_name: "doNotLookAtTheRoad",
          event_type: 6,
          event_status: 0,
        },
        {
          event_name: "smoke",
          event_type: 7,
          event_status: 0,
        },
        {
          event_name: "notFoundDriver",
          event_type: 8,
          event_status: 0,
        },
        {
          event_name: "cameraBlocked",
          event_type: 9,
          event_status: 0,
        },
      ],
      lasted_event: 1,
      link_video:
        "http://61.91.80.201:6611/3/5?DownType=3&DevIDNO=068110603010&FLENGTH=1081018&FOFFSET=0&MTYPE=1&FPATH=C%3A%2FgStorage%2FRECORD_FILE%2F068110603010%2F2024-10-21%2F02_02_6405_4_00068110603010241021204130240500.mp4&jsession=d10e5a2891fd49efbdb3c5abbe0b9aa",
    },
    {
      id: 3,
      vehicle_info: { plate_no: "MT-02020 (3321312)", code: "123" },
      alert: false,

      auto_play: false,
      event_stat: [
        {
          event_name: "vehicleCloseToTheFront",
          event_type: 1,
          event_status: 1,
        },
        {
          event_name: "straddlingLanesSuddenlyLeavingLanes",
          event_type: 2,
          event_status: 0,
        },
        {
          event_name: "nearPedestrians",
          event_type: 3,
          event_status: 0,
        },
        {
          event_name: "sleepyYawning",
          event_type: 4,
          event_status: 0,
        },
        {
          event_name: "call",
          event_type: 5,
          event_status: 2,
        },
        {
          event_name: "doNotLookAtTheRoad",
          event_type: 6,
          event_status: 0,
        },
        {
          event_name: "smoke",
          event_type: 7,
          event_status: 0,
        },
        {
          event_name: "notFoundDriver",
          event_type: 8,
          event_status: 0,
        },
        {
          event_name: "cameraBlocked",
          event_type: 9,
          event_status: 0,
        },
      ],
      lasted_event: 1,
      link_video:
        "http://61.91.80.201:6611/3/5?DownType=3&DevIDNO=068110603010&FLENGTH=1081018&FOFFSET=0&MTYPE=1&FPATH=C%3A%2FgStorage%2FRECORD_FILE%2F068110603010%2F2024-10-21%2F02_02_6405_4_00068110603010241021204130240500.mp4&jsession=d10e5a2891fd49efbdb3c5abbe0b9aa",
    },
    {
      id: 4,
      vehicle_info: { plate_no: "MT-02020 (3321312)", code: "123" },
      alert: false,
      auto_play: false,
      event_stat: [
        {
          event_name: "vehicleCloseToTheFront",
          event_type: 1,
          event_status: 1,
        },
        {
          event_name: "straddlingLanesSuddenlyLeavingLanes",
          event_type: 2,
          event_status: 0,
        },
        {
          event_name: "nearPedestrians",
          event_type: 3,
          event_status: 0,
        },
        {
          event_name: "sleepyYawning",
          event_type: 4,
          event_status: 0,
        },
        {
          event_name: "call",
          event_type: 5,
          event_status: 2,
        },
        {
          event_name: "doNotLookAtTheRoad",
          event_type: 6,
          event_status: 0,
        },
        {
          event_name: "smoke",
          event_type: 7,
          event_status: 0,
        },
        {
          event_name: "notFoundDriver",
          event_type: 8,
          event_status: 0,
        },
        {
          event_name: "cameraBlocked",
          event_type: 9,
          event_status: 0,
        },
      ],
      lasted_event: 1,
      link_video:
        "http://61.91.80.201:6611/3/5?DownType=3&DevIDNO=068110603010&FLENGTH=1081018&FOFFSET=0&MTYPE=1&FPATH=C%3A%2FgStorage%2FRECORD_FILE%2F068110603010%2F2024-10-21%2F02_02_6405_4_00068110603010241021204130240500.mp4&jsession=d10e5a2891fd49efbdb3c5abbe0b9aa",
    },
  ],
  total: 0,
};

const ColorStatus = {
  0: {
    color: "#CBCBCB",
    label: "ไม่เกิดเหตุการ",
  },
  1: {
    color: "#FFC031",
    label: "แจ้งเตือนแล้ว",
  },
  2: {
    color: "#F53C56",
    label: "ละเมิดนโยบาย",
  },
};

const DASHBOARD_TYPE = {
  video: {
    type: "video",
    label: "Video",
  },
  bookmark: {
    type: "bookmark",
    label: "Bookmarks",
  },
};

const DataDetail = {
  plate_no: "MT02-003021",
  driver_name: "Lorem Ipsum",
  driver_license_number: "1100xxx223xxxx",
  vehicle_code: "4805",
  vehicle_model: "MKMWKSPOX",
  engine_status: "Online",
  distance_time: {
    distance: "43.80",
    time: "492",
  },
  phone: "02-xxxx-xxxx",
  caution: "3",
  parking: "30",
  restingStatus: "จอดพักรถอยู่",
  address: "บางซ่อน",
  lat: "16.044301",
  lng: "101.174718",
  vehicle_id: "1432",
  data_table: [
    {
      number: 1,
      type: "ADAS",
      noti_type: "vehicleCloseToTheFrontReport",
      latest_noti: "01/11/2024 23:59:59",
      link:
        "http://61.91.80.201:6611/3/5?DownType=3&DevIDNO=068110603010&FLENGTH=1081018&FOFFSET=0&MTYPE=1&FPATH=C%3A%2FgStorage%2FRECORD_FILE%2F068110603010%2F2024-10-21%2F02_02_6405_4_00068110603010241021204130240500.mp4&jsession=d10e5a2891fd49efbdb3c5abbe0b9aa",
    },
    {
      number: 2,
      type: "DMS",
      noti_type: "sleepyYawningReport",
      latest_noti: "01/11/2024 23:59:59",
      link:
        "http://61.91.80.201:6611/3/5?DownType=3&DevIDNO=068110603010&FLENGTH=1081018&FOFFSET=0&MTYPE=1&FPATH=C%3A%2FgStorage%2FRECORD_FILE%2F068110603010%2F2024-10-21%2F02_02_6405_4_00068110603010241021204130240500.mp4&jsession=d10e5a2891fd49efbdb3c5abbe0b9aa",
    },
  ],
  data_graph: [
    {
      date: "20-11-2024",
      vehicleCloseToTheFront: 4,
      straddlingLanesSuddenlyLeavingLanes: 0,
      nearPedestrians: 10,
      sleepyYawning: 4,
      doNotLookAtTheRoad: 7,
      smoke: 1,
      notFoundDriver: 5,
      cameraBlocked: 10,
    },
    {
      date: "21-11-2024",
      vehicleCloseToTheFront: 3,
      straddlingLanesSuddenlyLeavingLanes: 4,
      nearPedestrians: 2,
      sleepyYawning: 10,
      doNotLookAtTheRoad: 5,
      smoke: 8,
      notFoundDriver: 4,
      cameraBlocked: 6,
    },
    {
      date: "22-11-2024",
      vehicleCloseToTheFront: 10,
      straddlingLanesSuddenlyLeavingLanes: 7,
      nearPedestrians: 1,
      sleepyYawning: 7,
      doNotLookAtTheRoad: 0,
      smoke: 1,
      notFoundDriver: 5,
      cameraBlocked: 8,
    },
    {
      date: "23-11-2024",
      vehicleCloseToTheFront: 0,
      straddlingLanesSuddenlyLeavingLanes: 8,
      nearPedestrians: 0,
      sleepyYawning: 2,
      doNotLookAtTheRoad: 2,
      smoke: 1,
      notFoundDriver: 9,
      cameraBlocked: 0,
    },
    {
      date: "24-11-2024",
      vehicleCloseToTheFront: 4,
      straddlingLanesSuddenlyLeavingLanes: 5,
      nearPedestrians: 3,
      sleepyYawning: 3,
      doNotLookAtTheRoad: 4,
      smoke: 1,
      notFoundDriver: 7,
      cameraBlocked: 6,
    },
    {
      date: "25-11-2024",
      vehicleCloseToTheFront: 10,
      straddlingLanesSuddenlyLeavingLanes: 6,
      nearPedestrians: 7,
      sleepyYawning: 8,
      doNotLookAtTheRoad: 6,
      smoke: 0,
      notFoundDriver: 3,
      cameraBlocked: 1,
    },
    {
      date: "26-11-2024",
      vehicleCloseToTheFront: 4,
      straddlingLanesSuddenlyLeavingLanes: 4,
      nearPedestrians: 10,
      sleepyYawning: 7,
      doNotLookAtTheRoad: 8,
      smoke: 6,
      notFoundDriver: 0,
      cameraBlocked: 0,
    },
    {
      date: "27-11-2024",
      vehicleCloseToTheFront: 4,
      straddlingLanesSuddenlyLeavingLanes: 6,
      nearPedestrians: 10,
      sleepyYawning: 0,
      doNotLookAtTheRoad: 3,
      smoke: 5,
      notFoundDriver: 2,
      cameraBlocked: 5,
    },
    {
      date: "28-11-2024",
      vehicleCloseToTheFront: 5,
      straddlingLanesSuddenlyLeavingLanes: 0,
      nearPedestrians: 4,
      sleepyYawning: 2,
      doNotLookAtTheRoad: 6,
      smoke: 10,
      notFoundDriver: 8,
      cameraBlocked: 6,
    },
    {
      date: "29-11-2024",
      vehicleCloseToTheFront: 0,
      straddlingLanesSuddenlyLeavingLanes: 3,
      nearPedestrians: 9,
      sleepyYawning: 1,
      doNotLookAtTheRoad: 8,
      smoke: 4,
      notFoundDriver: 9,
      cameraBlocked: 3,
    },
    {
      date: "30-11-2024",
      vehicleCloseToTheFront: 9,
      straddlingLanesSuddenlyLeavingLanes: 7,
      nearPedestrians: 9,
      sleepyYawning: 4,
      doNotLookAtTheRoad: 1,
      smoke: 2,
      notFoundDriver: 7,
      cameraBlocked: 10,
    },
  ],
  reference_noti: ["26-11-2024", "28-11-2024"],
  caution_data_table: [
    {
      number: 1,
      noti: "14-12-2024 23:59:59",
      driver_status: "บังคับหยุดทันที",
      parking_duration: "15",
      resting_type: "จอดพัก",
      parked_rested: {
        hour: 1,
        min: 15,
      },
    },
  ],
};

const driverNoti = {
  driver_status: [
    {
      id: 1,
      label: "บังคับหยุดพักทันที",
    },
  ],
  duration: [
    {
      id: 1,
      timing: 5,
    },
  ],
};

export {
  tabs,
  searchTree,
  icons,
  data,
  videoData,
  ColorStatus,
  DASHBOARD_TYPE,
  DataDetail,
  driverNoti,
};
