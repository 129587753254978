import moment from 'moment'

export const prepareDataForTable = (temp_data) => {
  let data_source = []

  for (let i = 0; i < temp_data.length; i++) {
    let item = temp_data[i]
    let vehicle_data = item.vehicle_data || {}
    let data = item.data || []
    if (
      Object.keys(item).length === 0 ||
      Object.keys(vehicle_data).length === 0 ||
      data.length === 0
    ) {
      continue
    }

    // ค่าเริ่มต้นของ row
    let array_item_data = []
    let item_data = {
      number_index: i + 1,
      type: '',
      vehicle_id: item.vehicle_id,
      vehicle_code: vehicle_data['vehicle_code'] || '',
      plate_no: vehicle_data['plate_no'] || '',
      device_code: vehicle_data['device_code'] || '',
      maintenance_data: vehicle_data['maintenance_data'] || null,
      number_of_series: item.number_of_series,
      visible: false,
    }

    // TODO:วนลูปตามจำนวน series
    for (let j = 0; j < item.number_of_series; j++) {
      let copy_item_data = {
        ...item_data,
        key: `${item.vehicle_id}_${j}`,
        series: j + 1,
        series_name: ``,
        type: 'gps-sensor',
        visible: j == 0 ? true : false,
      }

      let series_name = ''

      for (let k in data) {
        if (data.hasOwnProperty(k)) {
          let data_by_date = data[k]
          copy_item_data[k] = `${k}_data`
          series_name = data_by_date['series'][j].index_name
          let temp_d = {
            date: data_by_date.date,

            ...data_by_date['series'][j],
          }

          copy_item_data[`${k}_data`] = [
            {
              ...temp_d,
            },
          ]
        }
      }

      copy_item_data['series_name'] = series_name
      array_item_data.push(copy_item_data)
    }

    // เก็บข้อมูลใน data_source
    data_source = [...data_source, ...array_item_data]
  }

  // console.log('data_source', data_source)

  return data_source
}

export const prepareLogFormatArray = (month_data, start_date, end_date) => {
  let start_year = moment(start_date).year()
  let end_year = moment(end_date).year()
  let start_month = moment(start_date).month()
  let end_month = moment(end_date).month()
  let start_day = moment(start_date).date()
  let end_day = moment(end_date).date()

  // เก็บลำดับเดือนตั้งแต่ start_date ถึง end_date
  let month_order = []
  let month_order_key = []

  if (start_year == end_year) {
    // เรียงจากมากไปน้อย เก็บลำดับจาก end_month ถึง start_month
    for (let i = end_month; i >= start_month; i--) {
      month_order.push(i)
      month_order_key.push(month_data[i].key)
    }
  } else {
    // เรียงจากมากไปน้อย เก็บลำดับจาก end_month ถึง 0 แล้วจึงเก็บลำดับจาก 11 ถึง start_month
    for (let i = end_month; i >= 0; i--) {
      month_order.push(i)
      month_order_key.push(month_data[i].key)
    }

    for (let i = 11; i >= start_month; i--) {
      month_order.push(i)
      month_order_key.push(month_data[i].key)
    }
  }

  // เก็บวันที่ตั้งแต่ start_date ถึง end_date ใน format month_data[i].key_day
  let log_format_data = []
  log_format_data['order_title'] = month_order
  log_format_data['order_key'] = month_order_key

  let number_of_days = 0
  for (let i = 0; i < month_order.length; i++) {
    let date_array = []
    let day_array = []
    let month = month_data[month_order[i]]
    let days = month.days
    let start = 1
    let end = days
    if (month_order[i] == start_month) {
      start = start_day
    }
    if (month_order[i] == end_month) {
      end = end_day
    }
    for (let j = start; j <= end; j++) {
      let month_format =
        month.title + 1 < 10 ? `0${month.title + 1}` : `${month.title + 1}`
      let day_format = j < 10 ? `0${j}` : `${j}`
      let day_in_month = `${day_format}/${month_format}`
      let date = `${month.key}_${day_format}`
      date_array.push(date)
      day_array.push(day_in_month)

      number_of_days++
    }

    // sort date_array จากมากไปน้อย
    let temp_date_array = date_array.sort((a, b) => {
      return a > b ? -1 : 1
    })

    // sort day_array จากมากไปน้อย
    let temp_day_array = day_array.sort((a, b) => {
      return a > b ? -1 : 1
    })

    log_format_data[month.key] = {
      date_array: temp_date_array,
      day_array: temp_day_array,
    }
  }

  log_format_data['number_of_days'] = number_of_days

  return log_format_data
}
