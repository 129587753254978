import { createTypes, createAction } from "lib/action";

const LOAD_LISTVEHICLE_HONEYTOASTSTREAM = createTypes(
  "loadlistvehicle_honeytoaststream",
  "load"
);
const LOAD_VEHICLE_HONEYTOASTSTREAM = createTypes(
  "vehicle_honeytoaststream",
  "load"
);
const LOAD_DISPLAY = createTypes("load_display_video", "load");

const LOAD_USER_COMPANY = createTypes("load_user_company", "load");

const LOAD_DISPLAY_DETAIL = createTypes("load_display_detail", "load");

const CREATE_DISPLAY = createTypes("display", "create");
const UPDATE_DISPLAY = createTypes("display", "update");
const DELETE_DISPLAY = createTypes("display", "delete");

const createDisplay = {
  request: (text, generateMsg) =>
    createAction(CREATE_DISPLAY.REQUEST, { text, generateMsg }),
  success: (data) => createAction(CREATE_DISPLAY.SUCCESS, { data }),
  failure: () => createAction(CREATE_DISPLAY.FAILURE),
};

const updateDisplay = {
  request: (text, generateMsg) =>
    createAction(UPDATE_DISPLAY.REQUEST, { text, generateMsg }),
  success: (data) => createAction(UPDATE_DISPLAY.SUCCESS, { data }),
  failure: () => createAction(UPDATE_DISPLAY.FAILURE),
};

const deleteDisplay = {
  request: (text, generateMsg) =>
    createAction(DELETE_DISPLAY.REQUEST, { text, generateMsg }),
  success: (data) => createAction(DELETE_DISPLAY.SUCCESS, { data }),
  failure: () => createAction(DELETE_DISPLAY.FAILURE),
};

const loadDisplay = {
  request: (text) => createAction(LOAD_DISPLAY.REQUEST, { text }),
  success: (data) => createAction(LOAD_DISPLAY.SUCCESS, { data }),
  failure: () => createAction(LOAD_DISPLAY.FAILURE),
};
const loadDisplayDetail = {
  request: (text, callback) =>
    createAction(LOAD_DISPLAY_DETAIL.REQUEST, { text, callback }),
  success: (data) => createAction(LOAD_DISPLAY_DETAIL.SUCCESS, { data }),
  failure: () => createAction(LOAD_DISPLAY_DETAIL.FAILURE),
};
const loadListVehicleHoneytoast = {
  request: (data, loading, callback = () => {}) =>
    createAction(LOAD_LISTVEHICLE_HONEYTOASTSTREAM.REQUEST, {
      data,
      loading,
      callback,
    }),
  success: (data) =>
    createAction(LOAD_LISTVEHICLE_HONEYTOASTSTREAM.SUCCESS, { data }),
  failure: () => createAction(LOAD_LISTVEHICLE_HONEYTOASTSTREAM.FAILURE),
};

const loadVehicle = {
  request: (text = {}, callback = () => {}) =>
    createAction(LOAD_VEHICLE_HONEYTOASTSTREAM.REQUEST, { text, callback }),
  success: (data) =>
    createAction(LOAD_VEHICLE_HONEYTOASTSTREAM.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_HONEYTOASTSTREAM.FAILURE),
};
const loadUserCompany = {
  request: (text) => createAction(LOAD_USER_COMPANY.REQUEST, { text }),
  success: (data) => createAction(LOAD_USER_COMPANY.SUCCESS, { data }),
  failure: () => createAction(LOAD_USER_COMPANY.FAILURE),
};

export {
  loadListVehicleHoneytoast,
  LOAD_LISTVEHICLE_HONEYTOASTSTREAM,
  loadVehicle,
  LOAD_VEHICLE_HONEYTOASTSTREAM,
  loadDisplay,
  LOAD_DISPLAY,
  loadUserCompany,
  LOAD_USER_COMPANY,
  loadDisplayDetail,
  LOAD_DISPLAY_DETAIL,
  createDisplay,
  CREATE_DISPLAY,
  updateDisplay,
  UPDATE_DISPLAY,
  deleteDisplay,
  DELETE_DISPLAY,
};
