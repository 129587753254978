import { createTypes, createAction } from "lib/action";

const LOAD_ALARM_EVENT = createTypes("alarm_event", "load");

const LOAD_ADAS_EVENT_REPORT = createTypes("adas_event_report", "load");

const LOAD_VEHICLE_TYPE_WITH_VALUE = createTypes(
  "vehicle_type_with_value",
  "load"
);

const LOAD_ADAS_VEHICLE = createTypes("adas_vehicle", "load");

const loadAlarmEvent = {
  request: (data) => createAction(LOAD_ALARM_EVENT.REQUEST, { data }),
  success: (data) => createAction(LOAD_ALARM_EVENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_ALARM_EVENT.FAILURE),
};

const loadAdasEventReport = {
  request: (data) => createAction(LOAD_ADAS_EVENT_REPORT.REQUEST, { data }),
  success: (data) => createAction(LOAD_ADAS_EVENT_REPORT.SUCCESS, { data }),
  failure: () => createAction(LOAD_ADAS_EVENT_REPORT.FAILURE),
};

const loadVehicleTypeWithValue = {
  request: (data) =>
    createAction(LOAD_VEHICLE_TYPE_WITH_VALUE.REQUEST, { data }),
  success: (data) =>
    createAction(LOAD_VEHICLE_TYPE_WITH_VALUE.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_WITH_VALUE.FAILURE),
};

const loadAdasVehicle = {
  request: (data) => createAction(LOAD_ADAS_VEHICLE.REQUEST, { data }),
  success: (data) => createAction(LOAD_ADAS_VEHICLE.SUCCESS, { data }),
  failure: () => createAction(LOAD_ADAS_VEHICLE.FAILURE),
};

export {
  loadAlarmEvent,
  LOAD_ALARM_EVENT,
  loadAdasEventReport,
  LOAD_ADAS_EVENT_REPORT,
  loadVehicleTypeWithValue,
  LOAD_VEHICLE_TYPE_WITH_VALUE,
  loadAdasVehicle,
  LOAD_ADAS_VEHICLE,
};
