import { createTypes, createAction } from 'lib/action'

// *remove lists
const REMOVE_LISTS = `REMOVE_LISTS`

// *ดึงข้อมูล company list
const TAX_RENEWAL_READINESS_COMPANY_LIST = createTypes(
  'taxRenewalReadinessCompanyList',
  'load'
)

// *ดึงข้อมูล log
const TAX_RENEWAL_READINESS_LOG = createTypes('taxRenewalReadinessLog', 'load')

// *check dlt
const TAX_RENEWAL_READINESS_CHECK_DLT = createTypes(
  'taxRenewalReadinessCheckDlt',
  'load'
)

// *remove lists
function removeLists() {
  return {
    type: REMOVE_LISTS,
  }
}

// *ดึงข้อมูล company list
const taxRenewalReadinessCompanyList = {
  request: (data, loading) =>
    createAction(TAX_RENEWAL_READINESS_COMPANY_LIST.REQUEST, { data, loading }),
  success: (lists) =>
    createAction(TAX_RENEWAL_READINESS_COMPANY_LIST.SUCCESS, { lists }),
  failure: () => createAction(TAX_RENEWAL_READINESS_COMPANY_LIST.FAITLURE),
}

// *ดึงข้อมูล log
const taxRenewalReadinessLog = {
  request: (data) => createAction(TAX_RENEWAL_READINESS_LOG.REQUEST, { data }),
  success: (lists) =>
    createAction(TAX_RENEWAL_READINESS_LOG.SUCCESS, { lists }),
  failure: () => createAction(TAX_RENEWAL_READINESS_LOG.FAITLURE),
}

// *check dlt
const taxRenewalReadinessCheckDlt = {
  request: (data) =>
    createAction(TAX_RENEWAL_READINESS_CHECK_DLT.REQUEST, { data }),
  success: (lists) =>
    createAction(TAX_RENEWAL_READINESS_CHECK_DLT.SUCCESS, { lists }),
  failure: () => createAction(TAX_RENEWAL_READINESS_CHECK_DLT.FAITLURE),
}

export {
  TAX_RENEWAL_READINESS_COMPANY_LIST,
  taxRenewalReadinessCompanyList,
  TAX_RENEWAL_READINESS_LOG,
  taxRenewalReadinessLog,
  TAX_RENEWAL_READINESS_CHECK_DLT,
  taxRenewalReadinessCheckDlt,
  REMOVE_LISTS,
  removeLists,
}
