import moment from 'moment'

export const genBackgroundColor = (data, first_value, second_value = null) => {
  let engine_on_minute = data['engine_on_minute'] //เช็คได้ทั้ง rom และ sdcard เลย
  let color = ''

  if (engine_on_minute == 0) {
    color = '#f8f9fa'
  } else {
    // ให้ยึดตามค่าที่น้อยกว่า
    let value_for_check = first_value
    if (second_value != null) {
      value_for_check = Math.min(first_value, second_value)
    }

    if (value_for_check >= 90) {
      color = '#9FE2BF'
    } else if (value_for_check >= 50) {
      color = '#FFCE56'
    } else {
      color = '#ec8385'
    }
  }

  return color
}

export const prepareDataForTable = (temp_data) => {
  let data_source = []

  for (let i = 0; i < temp_data.length; i++) {
    let item = temp_data[i]
    let vehicle_data = item.vehicle_data || {}
    let data = item.sdcard || []
    let rom_data = item.rom || []
    if (
      Object.keys(item).length === 0 ||
      Object.keys(vehicle_data).length === 0 ||
      (data.length === 0 && rom_data.length === 0)
    ) {
      continue
    }

    // ค่าเริ่มต้นของ row
    let array_item_data = []
    let item_data = {
      number_index: i + 1,
      type: '',
      vehicle_id: item.vehicle_id,
      hardware_id: vehicle_data['hardware_id'] || '',
      vehicle_code: vehicle_data['vehicle_code'] || '',
      plate_no: vehicle_data['plate_no'] || '',
      device_code: vehicle_data['device_code'] || '',
      maintenance_data: vehicle_data['maintenance_data'] || null,
      // number_of_channel: item.number_of_channel + 1, //ถ้ามี online ให้เพิ่ม 1
      number_of_channel: item.number_of_channel, // ซ่อนการแสดงผล online ไว้ก่อน
      visible: false,
    }

    // TODO:วนลูปตามจำนวน Channel
    for (let j = 0; j < item.number_of_channel; j++) {
      let copy_item_data = {
        ...item_data,
        key: `${item.vehicle_id}_${j}`,
        channel: j + 1,
        channel_name: `Ch${j + 1}`,
        type: 'camera',
        visible: j == 0 ? true : false,
      }

      for (let k in data) {
        if (data.hasOwnProperty(k)) {
          let c_item = data[k]
          let rom_item = rom_data[k]
          copy_item_data[k] = `${k}_data`
          let temp_d = {
            date: c_item.date,
            engine_on_minute: c_item.engine_on_minute,

            rom_engine_on_minute: rom_item.engine_on_minute,
            rom_channel: rom_item['channel'][j].channel,
            rom_online_minute: rom_item['channel'][j].online_minute,
            rom_video_record_percent:
              rom_item['channel'][j].video_record_percent,
            rom_file_size: rom_item['channel'][j].file_size,

            ...c_item['channel'][j],
          }

          copy_item_data[`${k}_data`] = [
            {
              ...temp_d,
            },
          ]
        }
      }

      array_item_data.push(copy_item_data)
    }

    // TODO:ข้อมูลการออนไลน์ของอุปกรณ์
    // !ปิดไว้ก่อน
    // let copy_item_online = {
    //   ...item_data,
    //   key: `${item.vehicle_id}_online`,
    //   channel: 0,
    //   channel_name: 'online',
    //   type: 'online',
    //   visible: false,
    // }

    // for (let k in data) {
    //   if (data.hasOwnProperty(k)) {
    //     let c_item = data[k]
    //     copy_item_online[k] = `${k}_data`
    //     let temp_d = {
    //       ...c_item,
    //     }

    //     delete temp_d['channel']

    //     copy_item_online[`${k}_data`] = [
    //       {
    //         ...temp_d,
    //       },
    //     ]
    //   }
    // }
    // array_item_data.push(copy_item_online)
    // !ปิดไว้ก่อน

    // เก็บข้อมูลใน data_source
    data_source = [...data_source, ...array_item_data]
  }

  return data_source
}

export const prepareLogFormatArray = (month_data, start_date, end_date) => {
  let start_year = moment(start_date).year()
  let end_year = moment(end_date).year()
  let start_month = moment(start_date).month()
  let end_month = moment(end_date).month()
  let start_day = moment(start_date).date()
  let end_day = moment(end_date).date()

  // เก็บลำดับเดือนตั้งแต่ start_date ถึง end_date
  let month_order = []
  let month_order_key = []

  if (start_year == end_year) {
    // เรียงจากมากไปน้อย เก็บลำดับจาก end_month ถึง start_month
    for (let i = end_month; i >= start_month; i--) {
      month_order.push(i)
      month_order_key.push(month_data[i].key)
    }
  } else {
    // เรียงจากมากไปน้อย เก็บลำดับจาก end_month ถึง 0 แล้วจึงเก็บลำดับจาก 11 ถึง start_month
    for (let i = end_month; i >= 0; i--) {
      month_order.push(i)
      month_order_key.push(month_data[i].key)
    }

    for (let i = 11; i >= start_month; i--) {
      month_order.push(i)
      month_order_key.push(month_data[i].key)
    }
  }

  // เก็บวันที่ตั้งแต่ start_date ถึง end_date ใน format month_data[i].key_day
  let log_format_data = []
  log_format_data['order_title'] = month_order
  log_format_data['order_key'] = month_order_key

  let number_of_days = 0
  for (let i = 0; i < month_order.length; i++) {
    let date_array = []
    let day_array = []
    let month = month_data[month_order[i]]
    let days = month.days
    let start = 1
    let end = days
    if (month_order[i] == start_month) {
      start = start_day
    }
    if (month_order[i] == end_month) {
      end = end_day
    }
    for (let j = start; j <= end; j++) {
      let month_format =
        month.title + 1 < 10 ? `0${month.title + 1}` : `${month.title + 1}`
      let day_format = j < 10 ? `0${j}` : `${j}`
      let day_in_month = `${day_format}/${month_format}`
      let date = `${month.key}_${day_format}`
      date_array.push(date)
      day_array.push(day_in_month)

      number_of_days++
    }

    // sort date_array จากมากไปน้อย
    let temp_date_array = date_array.sort((a, b) => {
      return a > b ? -1 : 1
    })

    // sort day_array จากมากไปน้อย
    let temp_day_array = day_array.sort((a, b) => {
      return a > b ? -1 : 1
    })

    log_format_data[month.key] = {
      date_array: temp_date_array,
      day_array: temp_day_array,
    }
  }

  log_format_data['number_of_days'] = number_of_days

  return log_format_data
}
