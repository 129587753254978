import React from "react";

const Tab = ({ name, onClick, active }) => {
  return (
    <label
      onClick={onClick}
      className="dashboardAdasTabs"
      style={
        active
          ? {
              color: "white",
              backgroundColor: "black",
              padding: "13px 10px",
              borderRadius: 8,
              fontWeight: "bolder",
              cursor: "pointer",
              marginRight: 10,
            }
          : {
              padding: "13px 10px",
              fontWeight: "bolder",
              cursor: "pointer",
              marginRight: 10,
            }
      }
    >
      {name}
    </label>
  );
};

export default Tab;
