import React from "react";

export default function Clear(props) {
  const { fill } = props;
  return (
    <svg
      id="Group_31856"
      data-name="Group 31856"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="13"
      height="14"
      fill={fill}
      viewBox="0 0 13 14"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_21816"
            data-name="Rectangle 21816"
            width="13"
            height="14"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_31855" data-name="Group 31855" clip-path="url(#clip-path)">
        <path
          id="Path_44530"
          data-name="Path 44530"
          d="M35.625,1.853a1.858,1.858,0,0,1-.248.881c-.637,1.239-1.27,2.479-1.91,3.716a.311.311,0,0,0,0,.337,2.775,2.775,0,0,1,.244,2.271A27.486,27.486,0,0,1,31.4,14.473c-.179.3-.362.6-.552.9a1.238,1.238,0,0,1-1.56.539A18.313,18.313,0,0,1,24.658,13.4a16.876,16.876,0,0,1-2.646-2.278.939.939,0,0,1,.471-1.582,8.18,8.18,0,0,0,4.81-2.975,8.43,8.43,0,0,1,1.05-1.06,2.8,2.8,0,0,1,1.8-.608c.13,0,.175-.062.226-.162q.941-1.854,1.888-3.706A1.745,1.745,0,0,1,33.614.018a1.78,1.78,0,0,1,2.011,1.834m-3.532,8.686L27.374,8.023a8.761,8.761,0,0,1-4.434,2.55c.558.508,1.128.994,1.7,1.473a.186.186,0,0,0,.171-.007c.185-.117.364-.244.541-.374a.533.533,0,1,1,.655.839c-.111.095-.226.184-.365.3l1.767,1.11a4.6,4.6,0,0,0,.326-.355q.354-.476.69-.967a.541.541,0,0,1,.751-.208.516.516,0,0,1,.147.766c-.25.379-.527.74-.792,1.108-.032.045-.067.087-.118.153.411.163.8.311,1.186.472a.265.265,0,0,0,.382-.139c.362-.639.759-1.26,1.087-1.917.371-.743.681-1.517,1.022-2.287M28.145,7.217l4.294,2.289c.086-.243.167-.455.237-.671a1.891,1.891,0,0,0-.9-2.287c-.237-.131-.479-.251-.721-.371a1.973,1.973,0,0,0-1.59-.081,3.054,3.054,0,0,0-1.323,1.121M32.612,5.78c.032-.058.068-.119.1-.181l1.148-2.24c.211-.411.424-.82.63-1.234a.694.694,0,0,0-.063-.758.671.671,0,0,0-.678-.282.738.738,0,0,0-.564.453q-.869,1.715-1.744,3.426c-.026.05-.048.1-.074.157l1.244.66"
          transform="translate(-20.626 0)"
        />
        <path
          id="Path_44531"
          data-name="Path 44531"
          d="M0,133.083a.771.771,0,0,1,.774-.78.781.781,0,0,1-.019,1.562A.777.777,0,0,1,0,133.083"
          transform="translate(0 -125.37)"
        />
        <path
          id="Path_44532"
          data-name="Path 44532"
          d="M41.332,86.576a.778.778,0,0,1,.76.778.769.769,0,1,1-1.537,0,.771.771,0,0,1,.777-.777"
          transform="translate(-38.467 -82.039)"
        />
      </g>
    </svg>
  );
}
