import React, { Component } from "react";
import { Button, Icon } from "antd";

export default class CountdownButton extends Component {
  state = { timer: 60 };
  handleSetTimer = () => {
    this.intervalId = 0; // Stop the interval when countdown reaches 0
    clearInterval(this.intervalId);
    this.intervalId = setInterval(() => {
      if (this.state.timer <= 0) {
        this.setState({ timer: 60 });
      } else {
        this.setState({ timer: this.state.timer - 1 });
      }
    }, 1000);
  };
  componentDidMount() {
    this.handleSetTimer();
  }
  componentWillUnmount() {
    clearInterval(this.intervalId);
    this.intervalId = 0;
  }
  render() {
    return (
      <Button size="small" style={{ marginLeft: "5px" }} disabled>
        <Icon type="sync" spin />
        &nbsp;
        {this.state.timer}
      </Button>
    );
  }
}
